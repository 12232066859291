import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestValueChange } from '@vi/quest';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConfigurationResultService } from '../../services/configuration-result.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { LineType, Quote, QuoteAssignment, QuoteService } from '../../services/quote.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { CustomerSelection } from '../customer-selection/customer-selection.component';
import { LoadingOverlayComponent } from '../loading-overlay/loading-overlay.component';
import { PriceSummaryDataService } from '../price-summary/price-summary.data.service';
import { ProductListService } from '../product-list/product-list.service';
import {
    ConfigitConfiguration,
    ConfigitConfigurationAndProducts,
} from './configit-quest-adapter/configit-types.interface';
import { PackerConfigitQuestAdapterService } from './configit-quest-adapter/packer-configit-quest-adapter.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { environment } from '../../../../environments/environment';
import { QuestPartReset } from '@vi/quest/lib/quest-part-reset.interface';
import { openConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { ProductsService } from '../../services/products.service';
import { DropoutDialogComponent, DropoutDialogData } from '../dropout-dialog/dropout-dialog.component';
import { InstanaService } from '../../services/instana.service';
import { CustomRouteReuseStrategy } from '../../utils/route-reuse.strategy';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private dialogRef: MatDialogRef<LoadingOverlayComponent>;
    private selectedCustomer: CustomerSelection = { name: '', number: '' };
    private isPriceSummaryLoading = false;

    private showInitialModel: boolean;
    private hybridModel: {
        first: string;
        second: string;
    };

    public displayWaitMessage = false;
    public displaySelectCustomerMsg = false;
    public displayProvideTitleMsg = false;
    public displayMissingMainComponentMsg = false;

    public preselectPart = 0;

    public quest$ = new Subject<Quest>();
    public currentModel: Quest;

    @Input()
    public quickRef: string;
    @Input()
    public documentId: string;
    @Input()
    public latestRevision: string;
    @Input()
    public title: string;
    @Input()
    public salesforceId: string;
    @Input()
    public quotationRequestId: string;
    @Input()
    public planning: boolean;
    @Input()
    public lineType: LineType;

    @Output()
    public isReady = new EventEmitter<boolean>();

    @Output()
    public isComplete = new EventEmitter<boolean>();

    constructor(
        private quests: PackerConfigitQuestAdapterService,
        private router: Router,
        private quoteService: QuoteService,
        private selectedCustomerService: SelectedCustomerService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private instana: InstanaService,
        private productListService: ProductListService,
        private productService: ProductsService,
        private dialog: MatDialog,
        private permissionService: PermissionService,
        private priceSummaryDataService: PriceSummaryDataService,
        private productCategoryService: ProductsCategoryService,
        private configurationResultService: ConfigurationResultService
    ) {}

    public ngOnInit() {
        this.configurationResultService.configuration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.loadQuote();
        this.subscribeToSelectedCustomer$();
        this.subscribeToPriceSummaryLoading$();
        this.subscribeToQuest();
    }

    public page(): void {}

    public reset(event: QuestPartReset) {
        const title = this.translateService.instant('CONFIGURATION.DIALOG.RESET.TITLE');
        const question = this.translateService.instant('CONFIGURATION.DIALOG.RESET.MESSAGE', {
            page: event.part.title,
        });
        const yes = this.translateService.instant('CONFIGURATION.DIALOG.RESET.CONFIRM');
        const no = this.translateService.instant('CONFIGURATION.DIALOG.RESET.CANCEL');
        openConfirmationDialog(this.dialog, title, question, yes, no, (confirmed) => {
            if (confirmed) {
                this.openDialog();
                this.productCategoryService.clearCategoriesCache();
                this.quests
                    .reset(event)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((model: Quest) => {
                        this.setModel(model);
                        this.quoteService.updateQuoteAssignments(
                            model.original.assignments,
                            model.original.template.name
                        );
                        this.closeDialog();
                    });
            } else {
                // set current model as otherwise quest will stay in "checking" state
                this.setModel(this.currentModel);
            }
        });
    }

    public check(changed: QuestValueChange) {
        this.quests
            .check(changed)
            .pipe(map((result: QuestCheckResult) => result.model))
            .subscribe(
                (model: Quest) => {
                    this.setModel(model);

                    this.quoteService.updateQuoteAssignments(model.original.assignments, model.original.template.name);
                    if (this.showInitialModel && this.checkComplete(model)) {
                        // switch to the secondary model as soon as the primary model is complete
                        this.switchToSecondaryModel(model);
                    }
                },
                () => {
                    this.onError('CONFIGURATION.SNACK_BAR.UPDATE_VALUES.ERROR');
                }
            );
    }

    private switchToModel(quest: Quest, material: string, lineType: LineType) {
        // copy all assignments from Miscellaneous.PreSelection to the new model as user assignment
        const copyAssignments: QuoteAssignment[] = quest.original.assignments
            .filter((a) => a.variableName.startsWith(environment.quest.preselectionVariable))
            .map((a) => ({ ...a, isUserAssignment: true }));
        this.openDialog();
        this.quoteService
            .getQuoteSaveObservable()
            .pipe(
                concatMap(() => this.quests.getConfiguration(material, copyAssignments)),
                concatMap(({ bomItems }) =>
                    forkJoin([
                        of(bomItems),
                        this.productService.getMaterialInfos([material, ...bomItems.map((b) => b.material)], false),
                    ])
                ),
                concatMap(([bomItems, materialInfos]) =>
                    this.quoteService.createAdditionalLine(materialInfos, lineType, bomItems, copyAssignments)
                ),
                concatMap(() => this.productListService.validateMainComponent(quest.id))
            )
            .subscribe({
                next: (validateResult) => {
                    this.closeDialog();
                    if (validateResult.hasMainComponent) {
                        this.navigateToModel(lineType);
                    } else {
                        this.sendNoMainComponentNotification();
                        this.displayMissingMainComponentMsg = true;
                    }
                },
                error: (e) => {
                    console.error(e);
                    this.onError('CONFIGURATION.SNACK_BAR.SAVE_CONFIGURATION.ERROR');
                    this.closeDialog();
                },
            });
    }

    private navigateToModel(lineType: string) {
        const strategy = <CustomRouteReuseStrategy>this.router.routeReuseStrategy;
        strategy.canReuseRoute = false;
        this.router
            .navigate([], {
                queryParams: { lineType },
                queryParamsHandling: 'merge',
            })
            .then(() => {
                this.closeDialog();
                strategy.canReuseRoute = true;
            });
    }

    private switchToSecondaryModel(quest: Quest) {
        const material = quest.original.assignments
            .find((a) => a?.variableName === environment.quest.modelSelectionVariable)
            .valueName.split(';')[0];
        this.switchToModel(quest, material, LineType.secondary);
    }

    private switchToTertiaryModel(quest: Quest, material: string) {
        this.switchToModel(quest, material, LineType.tertiary);
    }

    public submit(submittedModel?: Quest | Promise<Quest>) {
        const model: Quest = submittedModel ? <Quest>submittedModel : this.currentModel;

        if (this.isFirstHybridModel(model)) {
            // skip validation so we can go to second hybrid model
            this.switchToTertiaryModel(model, this.hybridModel.second);
            return;
        }

        this.displayWaitMessage = false;
        // Display error if there is no text in the title input
        this.displayProvideTitleMsg = !this.title;

        // when user is an employee he must first select a customer that the quote will be assigned to
        if (!this.selectedCustomer.number && this.permissionService.isUserAnEmployee) {
            this.displaySelectCustomerMsg = true;
        }

        // Don't submit when errors are displayed
        if (this.displayProvideTitleMsg || this.displaySelectCustomerMsg) {
            return;
        }

        // wait till products and price finishes loading
        if (this.productListService.isProductListLoading$.value || this.isPriceSummaryLoading) {
            this.displayWaitMessage = true;

            setTimeout(() => {
                this.submit(model);
            }, 2000);
            return;
        }

        this.openDialog();

        this.configurationResultService.configuration$
            .pipe(
                take(1),
                concatMap(({ bomItems }) => this.quests.getEnergyLabelFromConfiguration(model, bomItems)),
                switchMap((energyLabelHash) => {
                    if (energyLabelHash) {
                        return this.quoteService.updateQuoteProperty('energyLabelHash', energyLabelHash);
                    }
                    return of(null);
                }),
                concatMap(() => this.productListService.validateMainComponent(model.id))
            )
            .subscribe({
                next: (result) => {
                    this.closeDialog();

                    if (result.hasMainComponent) {
                        const priceSummary = this.priceSummaryDataService.getLastPriceSummary();
                        const accessoryItems = (priceSummary.grossPrices || []).filter((item) =>
                            result.accessoryProducts.includes(item.material)
                        );
                        const accessoryTotalGross = accessoryItems.reduce((sum, item) => sum + item.gross, 0);
                        const totalGross = priceSummary.prices.totalGross;
                        const percentage = (accessoryTotalGross * 100) / totalGross;
                        if (percentage && percentage < 3) {
                            this.sendAccessoryNotification({ percentage, accessoryTotalGross, totalGross });
                        }
                        this.navigateToSummaryPage();
                    } else {
                        this.sendNoMainComponentNotification();
                        this.displayMissingMainComponentMsg = true;
                    }
                },
                error: (e) => {
                    console.error(e);
                    this.onError('CONFIGURATION.SNACK_BAR.SAVE_CONFIGURATION.ERROR');
                    this.closeDialog();
                },
            });
    }

    private sendNoMainComponentNotification() {
        this.sendInstanaNotification('mainComponent', 'No main component for quote');
    }

    private sendAccessoryNotification(data: { percentage: number; accessoryTotalGross: number; totalGross: number }) {
        this.sendInstanaNotification('accessory', 'Accessory less than 3% for quote', data);
    }

    private sendInstanaNotification(
        customType: 'mainComponent' | 'accessory',
        message: string,
        data: Record<string, string | number> = {}
    ) {
        const quote = this.quoteService.getCurrentQuote();
        if (quote) {
            const { quickRef, title: quoteTitle, documentId } = quote.quote;
            this.instana.reportError(`${message} ${quickRef}`, {
                customType,
                documentId,
                quoteTitle,
                quickRef,
                ...data,
            });
        }
    }

    private checkComplete(model: Quest): boolean {
        const firstIncomplete = model.parts.findIndex((p) => !p.valid);
        return firstIncomplete === -1;
    }

    private isFirstHybridModel(model: Quest) {
        return this.hybridModel && model.id === this.hybridModel.first;
    }

    private subscribeToQuest() {
        this.quest$.asObservable().subscribe((model: Quest) => {
            this.currentModel = model;
            this.isComplete.next(this.checkComplete(model) && !this.isFirstHybridModel(model));
        });
    }

    protected setModel(model: Quest): void {
        this.quest$.next(model);
        if (this.showInitialModel) {
            return;
        }
        if (this.isFirstHybridModel(model)) {
            const lastPart = model.parts[model.parts.length - 1];
            // quest.button.submit-hybrid
            lastPart.submit = 'submit-hybrid';
        }
        if (this.hybridModel && model.id === this.hybridModel.second) {
            // enable "exit" button on first part, which will switch back to first of hybrid models
            model.parts[0].exit = true;
        }
    }

    private onError(translationKey: string) {
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
            isFailure: true,
        });
    }

    private openDialog(): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '250px';
            dialogConfig.scrollStrategy = new NoopScrollStrategy();
            this.dialogRef = this.dialog.open(LoadingOverlayComponent, dialogConfig);
        }
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    private subscribeToPriceSummaryLoading$() {
        this.priceSummaryDataService.isPriceSummaryLoading$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isLoading) => {
                this.isPriceSummaryLoading = isLoading.value;
            });
    }

    private loadQuote() {
        this.quoteService
            .fetchQuote({ documentId: this.documentId, latestRevision: this.latestRevision })
            .subscribe((quote) => this.setupQuest(quote));
    }

    private setupQuest(quote: Quote) {
        if (quote.quote.status.toLowerCase() !== 'new') {
            this.router.navigate(['next-steps'], {
                queryParams: {
                    documentId: this.documentId,
                    latestRevision: this.latestRevision,
                    sapDocumentId: quote.quote.salesDocumentNumber,
                    printType: quote.printType,
                },
            });
        }
        if (this.salesforceId) {
            this.quoteService.updateQuoteProperty('salesforceId', this.salesforceId);
        }
        const primaryLine = this.quoteService.getPrimaryLine(quote.quote);
        if (!primaryLine) {
            // this means that we have a quote with only external products.
            // we should go to the summary page
            this.navigateToSummaryPage();
        }
        const secondaryLine = this.quoteService.getSecondaryLine(quote.quote);
        const assignment = primaryLine.assignments.find(
            (a) => a.variableName === environment.quest.modelSelectionVariable
        );
        if (assignment?.valueName?.includes(';')) {
            const [first, second] = assignment.valueName.split(';');
            this.hybridModel = { first, second };
        }
        this.showInitialModel = !secondaryLine;
        const quoteLine = this.lineType && this.quoteService.getLineByType(quote.quote, this.lineType);
        const line = quoteLine || secondaryLine || primaryLine;
        const material = line?.variantCode || environment.quest.project;

        // always go to first part
        this.preselectPart = 0;

        // check if configuration is supported when a planning id is set in the quote object
        // this only needs to be done for the first model (QuoteAssist).
        // if there are further lines, then the first model was already valid and complete.
        const checkSupport = !!quote.planningId && material === environment.quest.project;
        this.quests
            .getWithConfiguration(
                material,
                <Observable<ConfigitConfigurationAndProducts>>(
                    this.configurationResultService.getConfigurationForQuoteLine(line, checkSupport)
                )
            )
            .subscribe(
                (model) => {
                    if (!model || !model.original) {
                        // if model is NULL skip all operations
                        return;
                    }
                    if ((<ConfigitConfiguration>model.original.configuration).unsupported) {
                        // planning usecase with unsupported configuration,
                        // show a dropout dialog
                        return this.showDropoutForUnsupported(quote.salesforceId);
                    }

                    this.setModel(model);
                    this.isReady.emit(true);
                    this.closeDialog();
                },
                () => {
                    this.onError('CONFIGURATION.SNACK_BAR.FETCH_CONFIGURATION.ERROR');
                    this.closeDialog();
                }
            );
    }

    private showDropoutForUnsupported(salesforceId: string) {
        // planning usecase with unsupported configuration,
        // show a dropout dialog
        const data: DropoutDialogData = {
            title: this.translateService.instant('CONFIGURATION.DROPOUT.UNSUPPORTED.TITLE'),
            text: this.translateService.instant('CONFIGURATION.DROPOUT.UNSUPPORTED.TEXT'),
        };
        const dialogRef = this.dialog.open(DropoutDialogComponent, { data });
        dialogRef.afterClosed().subscribe(() => {
            if (salesforceId) {
                // this usecase can only happen for planning quotes, so we should always have salesforceId
                this.router.navigate(['planning-project', salesforceId]);
            } else {
                this.router.navigate(['overview']);
            }
        });
        this.isReady.emit(true);
    }

    private subscribeToSelectedCustomer$() {
        this.selectedCustomerService.selectedCustomer$
            .pipe(takeUntil(this.ngUnsubscribe), filter(Boolean))
            .subscribe((customer: CustomerSelection) => {
                this.selectedCustomer = customer;
                this.displaySelectCustomerMsg =
                    this.selectedCustomer.number === undefined && this.permissionService.isUserAnEmployee;
            });
    }

    private navigateToSummaryPage() {
        const salesforceId = this.salesforceId;
        const quotationRequestId = this.quotationRequestId;
        this.router.navigate(['summary'], {
            queryParams: {
                quickRef: this.quickRef,
                documentId: this.documentId,
                latestRevision: this.latestRevision,
                title: this.title,
                ...(this.planning && { planning: true }),
                ...(salesforceId && { salesforceId }),
                ...(quotationRequestId && { quotationRequestId }),
            },
        });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public backToSecondary() {
        this.openDialog();
        this.navigateToModel(LineType.secondary);
    }
}
