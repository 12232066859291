<div data-ut-main-content class="main-content" fxLayout="column">
    <div fxFlex="0 1 auto">
        <div fxLayout="row" fxLayoutAlign="center start">
            <div
                fxFlex="1064px"
                fxFlex.lt-lg="100%"
                fxLayout="row"
                fxLayoutAlign="space-between start"
                class="content-box"
            >
                <div data-ut-content-left fxFlex="500px" fxFlex.lt-lg="50%">
                    <ng-content select="[left]"></ng-content>
                </div>
                <div data-ut-content-right fxFlex="500px" fxFlex.lt-lg="50%">
                    <ng-content select="[right]"></ng-content>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex="0 1 auto" fxFlexFill>
        <div fxLayout="row" fxLayoutAlign="center start" fxFlexFill>
            <div
                data-ut-content-main
                fxFlex="0 1 1500px"
                fxFlex.lt-xl="0 1 1280px"
                fxFlex.lt-lg="100%"
                fxLayout="column"
                fxLayoutGap="24px"
                fxLayoutAlign="start start"
                class="content-box one-column"
            >
                <ng-content select="[main]"></ng-content>
            </div>
        </div>
    </div>
</div>
