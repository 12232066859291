import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { PermissionService } from '../_shared/services/permission.service';

export const forbiddenAuthGuardService: CanActivateFn = () => {
    const permissionService: PermissionService = inject(PermissionService);
    const router: Router = inject(Router);
    return permissionService.isUserAuthorized$.pipe(
        filter((v) => v !== undefined),
        map((v) => !v),
        tap((shouldDisplayForbiddenPage) => {
            if (shouldDisplayForbiddenPage === false) {
                router.navigate(['overview']);
            }
        })
    );
};
