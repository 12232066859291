import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppService } from './_shared/services/app.service';
import { FeatureService } from './_shared/services/feature.service';
import { HttpService } from './_shared/services/http.service';
import { PermissionService } from './_shared/services/permission.service';
import { SnackBarService } from './_shared/services/snack-bar.service';
import { UrlService } from './_shared/services/url.service';
import { SelectedCustomerService } from './_shared/services/selected-customer.service';
import { InstanaService } from './_shared/services/instana.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public isInternetExplorer = false;
    private currentUserRole: 'unknown' | 'customer' | 'employee';
    constructor(
        private instana: InstanaService,
        private translate: TranslateService,
        public appService: AppService,
        private urlService: UrlService,
        private http: HttpService,
        private snackbarService: SnackBarService,
        public feature: FeatureService,
        public permissionService: PermissionService,
        private selectedCustomerService: SelectedCustomerService,
        private router: Router,
        private titleService: Title
    ) {}

    private routerSubscription: any;

    public ngOnInit() {
        combineLatest([this.appService.appTitle$, this.translate.onLangChange]).subscribe(([appTitle]) => {
            this.titleService.setTitle(appTitle || this.translate.instant('HEADER.APP-TITLE'));
        });
        this.getAvailableLanguages();

        this.routerSubscription = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && val.urlAfterRedirects !== '/') {
                this.routerSubscription.unsubscribe();
                this.navigateToLastPage();
            }
        });

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.instana.reportPage(val.urlAfterRedirects);
            }
        });
    }

    private navigateToLastPage() {
        const lastPath = localStorage.getItem('lastPath');
        const lastSearch = localStorage.getItem('lastSearch');
        if (lastPath && lastPath !== '/') {
            let params = {};

            if (lastSearch) {
                try {
                    const search = lastSearch.substring(1);
                    params = JSON.parse(
                        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                        (key, value) => {
                            return key === '' ? value : decodeURIComponent(value);
                        }
                    );
                } catch (e) {
                    localStorage.removeItem('lastSearch');
                }
            }

            this.router
                .navigate([lastPath], {
                    queryParams: params,
                })
                .then(() => {
                    localStorage.removeItem('lastPath');
                    localStorage.removeItem('lastSearch');
                });
        }
    }

    private getAvailableLanguages() {
        this.permissionService.userInfo$.subscribe((user) => {
            if (user === undefined || user.role === this.currentUserRole) {
                return;
            }
            this.currentUserRole = user.role;
            if (user.role === 'employee') {
                const url = this.urlService.getUrl('employee.languages');
                this.http.get(url).subscribe(
                    (res: EmployeeSalesFunctionsResponse) => {
                        if (!res.salesFunctions || res.salesFunctions.length < 1) {
                            // if requirements of users salesOrg is disabled, we set status to true
                            this.permissionService.languagesReady$.next(!environment.requireSalesOrg);
                            return;
                        }
                        const availableSalesOrgs = Array.from(new Set(res.salesFunctions.map((sf) => sf.salesOrg)));
                        this.handleLanguages(availableSalesOrgs);
                    },
                    () => this.onLanguagesError()
                );
            } else {
                const url = this.urlService.getUrl('customer.languages');
                this.http.get(url).subscribe(
                    (res) => {
                        this.selectedCustomerService.isPlannerOrArchitect$.next(res.architectPlanner);
                        const availableSalesOrgs: string[] = Array.from(
                            new Set(res.salesOrgs.map((so) => so.salesOrg))
                        );
                        const primarySalesOrg = res.primarySalesOrg;
                        this.handleLanguages(availableSalesOrgs, primarySalesOrg);
                    },
                    () => this.onLanguagesError()
                );
            }
        });
    }

    private onLanguagesError() {
        this.permissionService.languagesReady$.next(false);
        this.onError();
    }

    private handleLanguages(salesOrgs: string[], primarySalesOrg?: string) {
        this.appService.availableSalesOrgs$.next(salesOrgs);
        const availableLanguages = environment.app.supportedLanguages.filter((lg) => salesOrgs.includes(lg.salesOrg));
        const selectableLanguages = availableLanguages.filter((lg) =>
            this.appService.isSalesOrgSelectable(lg.salesOrg)
        );

        if (primarySalesOrg === undefined) {
            primarySalesOrg = salesOrgs.find((salesOrg) =>
                selectableLanguages.some((language) => language.salesOrg === salesOrg)
            );
        }

        let currentLang = this.appService.getLocalStorageLanguage();
        let currentSalesOrg = this.appService.getLocalStorageSalesOrg();
        if (
            !currentLang ||
            !currentSalesOrg ||
            !selectableLanguages.some((al) => al.locale === currentLang && al.salesOrg === currentSalesOrg)
        ) {
            const defaultSalesOrg = selectableLanguages.find((o) => o.salesOrg === primarySalesOrg);
            if (defaultSalesOrg) {
                currentLang = defaultSalesOrg.locale;
                currentSalesOrg = primarySalesOrg;
            }
        }
        const languageFromQuery = new URLSearchParams(window.location.search).get('lang');
        if (languageFromQuery) {
            // here we intentionally use all available languages, not only selectable ones
            const match = availableLanguages.find((l) => l.locale.startsWith(languageFromQuery));
            if (match) {
                currentLang = match.locale;
                currentSalesOrg = match.salesOrg;
            }
        }

        // do not store language from query parameter in local storage
        const persistent = !languageFromQuery;

        this.translate.setDefaultLang(currentLang);
        this.appService.setLanguage(currentLang, persistent);
        this.appService.setSalesOrg(currentSalesOrg, persistent);

        this.appService.availableLanguages$.next(selectableLanguages);
        this.permissionService.languagesReady$.next(true);
    }

    private onError(): void {
        if (this.permissionService.isUserAuthorized$.value) {
            this.snackbarService.openSnackBar({
                message: this.translate.instant('COMMON.HEADER.USER_NAME.FAILURE_MSG'),
                isFailure: true,
            });
        }
    }
}

interface EmployeeSalesFunctionsResponse {
    salesFunctions: { salesOrg: string }[];
}
