<app-card main [isHeader]="true" [isFooter]="false">
    <div header fxLayoutAlign="space-between" class="scheme-preview-title">
        <h2>{{ 'SCHEMEPREVIEW.CARD.TITLE' | translate }}</h2>
    </div>
    <div body fxLayout="row" fxLayoutAlign="space-between center" class="scheme-preview-body">
        <a href="{{ schemeDownloadUrl(primaryScheme) }}" target="_blank">
            <img src="{{ schemeDownloadUrl(primaryScheme, true) }}" />
        </a>
    </div>
</app-card>
