import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { FeedbackDialogComponent } from '../_shared/components/feedback-dialog/feedback-dialog.component';
import { AppService } from '../_shared/services/app.service';
import { AuthService } from '../_shared/services/auth.service';
import { PermissionService } from '../_shared/services/permission.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public lang: string;

    public username = '...';
    public userId = '...';
    public faqLink: string;
    public authenticated$: Observable<boolean>;

    constructor(
        public appService: AppService,
        public permissionService: PermissionService,
        public dialog: MatDialog,
        public authService: AuthService
    ) {
        this.authenticated$ = this.permissionService.isUserLoggedIn$.pipe(
            filter((v) => v !== undefined),
            delay(10)
        );
    }

    public ngOnInit() {
        this.lang = this.appService.getLanguageOnly();
        this.permissionService.userInfo$.subscribe((user) => {
            if (user && user.displayName && user.userId) {
                this.username = user.displayName;
                this.userId = user.userId;
            }
        });
        this.updateFaqLink();
        this.appService.salesOrg$.subscribe(() => {
            this.updateFaqLink();
        });
    }
    private updateFaqLink() {
        const faq = environment.http.faq;
        const salesOrg = this.appService.getSalesOrg();
        this.faqLink = faq[salesOrg] || faq.default;
    }

    public onLanguageChanged(lang: string): void {
        this.appService.setLanguage(lang, true);
    }

    public onSalesOrgChanged(salesorg: string): void {
        this.appService.setSalesOrg(salesorg, true);
    }

    public openFeedbackDialog(): void {
        this.dialog.open(FeedbackDialogComponent);
    }
}
