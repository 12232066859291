<div
    class="feature-banner"
    fxLayout="column"
    *ngIf="(featureMessage$ | async) && (appService.appTitle$ | async) === ''"
>
    <div fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon>info</mat-icon>
        <span [innerHTML]="featureMessage$ | async"></span>
    </div>
</div>
