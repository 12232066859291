export class ConfigitUtils {
    /**
     * Transforms array collection to keyed object
     *
     * @param data Data collection
     * @param prop Property to get value for key
     */
    public static objectize(data: any[], prop: string): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        data.forEach((item) => (result[item[prop]] = item));

        return result;
    }

    public static objectify(data: any[], prop: string): Map<string, any> {
        const result = new Map<string, any>();

        data.forEach((item) => result.set(item[prop], item));

        return result;
    }
}
