import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CustomerSelection } from '../components/customer-selection/customer-selection.component';
import { Quote, QuoteService } from './quote.service';

@Injectable({
    providedIn: 'root',
})
export class SelectedCustomerService {
    constructor(quoteService: QuoteService) {
        this.selectedCustomer$
            .pipe(
                filter((c) => c !== undefined && c.number !== ''),
                switchMap((customer) => quoteService.updateQuoteProperty('companyId', customer.number))
            )
            .subscribe();
        this.selectedCustomer$.subscribe((c) => {
            if (c?.calculationSchema) {
                this.isPlannerOrArchitect$.next(c.calculationSchema === '8');
            }
        });
        quoteService
            .getCurrentQuoteObservable()
            .pipe(
                filter(Boolean),
                filter((q: Quote) => q.isNew)
            )
            .subscribe(({ selectedCustomer }) => {
                this.selectedCustomer$.next({ number: selectedCustomer });
            });
    }
    public selectedCustomer$: BehaviorSubject<CustomerSelection | undefined> = new BehaviorSubject<CustomerSelection>(
        undefined
    );

    public customerForPartnerMode$: BehaviorSubject<CustomerSelection | undefined> =
        new BehaviorSubject<CustomerSelection>(undefined);

    public isPlannerOrArchitect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public clearSelectedCustomer() {
        this.selectedCustomer$.next(undefined);
    }
}
