import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { openConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { Locale } from '../../services/app.service';

@Component({
    selector: 'app-salesorg-dropdown',
    templateUrl: './salesorg-dropdown.component.html',
    styleUrls: ['./salesorg-dropdown.component.scss'],
})
export class SalesorgDropdownComponent {
    @Input()
    public selectedLanguage: string;

    @Output()
    public languageChanged = new EventEmitter<string>();

    @Output()
    public salesOrgChanged = new EventEmitter<string>();

    @Input()
    public availableOptions: Locale[];

    constructor(
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
        public translate: TranslateService
    ) {}

    public selectLanguage(lang) {
        this.languageChanged.emit(lang);
    }

    public selectSalesOrg(location) {
        this.salesOrgChanged.emit(location);
    }

    public canChangeLocation(route?: ActivatedRouteSnapshot) {
        if (!route) {
            return this.canChangeLocation(this.route.snapshot);
        }
        if (route.data['canChangeLocation']) {
            return true;
        } else {
            return route.children.map((it) => this.canChangeLocation(it)).some(Boolean);
        }
    }

    public askAndSelectLocale(locale: Locale) {
        if (this.canChangeLocation()) {
            this.selectLocale(locale);
        } else {
            openConfirmationDialog(
                this.dialog,
                this.translate.instant('LANGUAGE_DROPDOWN.RETURN_TO_OVERVIEW.TITLE'),
                this.translate.instant('LANGUAGE_DROPDOWN.RETURN_TO_OVERVIEW.QUESTION'),
                this.translate.instant('LANGUAGE_DROPDOWN.RETURN_TO_OVERVIEW.YES'),
                this.translate.instant('LANGUAGE_DROPDOWN.RETURN_TO_OVERVIEW.NO'),
                (confirmed) => {
                    if (confirmed) {
                        this.selectLocale(locale);
                        this.router.navigate(['overview']);
                    }
                }
            );
        }
    }

    public selectLocale(locale: Locale) {
        this.selectLanguage(locale.locale);
        this.selectSalesOrg(locale.salesOrg);
    }
}
