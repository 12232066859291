import { Injectable } from '@angular/core';
import { concat, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mapTo, retryWhen, take } from 'rxjs/operators';
import { UrlService } from '../_shared/services/url.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface PrintOfferPayload {
    language: string;
    country: string;
    documentId: string;
    latestRevision: string;
    printType: string;
}

export interface Attachment {
    id: string;
    type: string;
    fileName: string;
    size: number;
    uploadedAt: string;
    visibility: string;
    owner: string;
}

export interface AdditionalAttachment {
    id: string;
    name: string;
    url: string;
    mimetype: string;
}

@Injectable({
    providedIn: 'root',
})
export class NextStepsDataService {
    constructor(private urlService: UrlService, private http: HttpClient) {}

    public fetchOfferPdf(payload: PrintOfferPayload) {
        // check if pdf is already available. if not, start generation process
        return this.tryDownload(payload).pipe(
            concatMap((url) => (url ? of({ isError: false, url }) : this.fetchPrintOfferPdf(payload)))
        );
    }

    private fetchPrintOfferPdf(payload: PrintOfferPayload) {
        // first request to initiate task of generating pdf
        return this.initiatePrintOfferPdf(payload).pipe(
            delay(2000),
            concatMap((res) => {
                // second request to check the state of pdf task (interval of 2000)
                return this.callGetTaskState({
                    latestRevision: payload.latestRevision,
                    taskId: res.taskId,
                    documentId: payload.documentId,
                    language: payload.language,
                    country: payload.country,
                });
            }),
            concatMap((result) =>
                this.notifySalesRep(payload).pipe(
                    mapTo(result),
                    catchError(() => of(result))
                )
            )
        );
    }

    private notifySalesRep(payload: PrintOfferPayload) {
        return this.http.post(
            `${environment.http.baseUrl}quote/${payload.documentId}/revision/${payload.latestRevision}/notifySalesRep`,
            {
                language: payload.language,
                country: payload.country,
            }
        );
    }

    private tryDownload(payload: PrintOfferPayload): Observable<string | undefined> {
        const downloadUrl = this.getPdfDownloadUrl(payload);
        return this.http.head(downloadUrl, { observe: 'response' }).pipe(
            map((response) => (response.status === 200 ? downloadUrl : undefined)),
            catchError(() => of(undefined))
        );
    }

    private getTaskState(params): Observable<any> {
        const url = this.urlService.getUrl('pdf.offer.taskState', params);
        return this.http.post(url, {
            language: params.language,
            country: params.country,
            taskId: params.taskId,
        });
    }

    private getPdfDownloadUrl(params): string {
        return this.urlService.getUrl('pdf.offer.download', params);
    }

    private initiatePrintOfferPdf(params: any): Observable<any> {
        const url = this.urlService.getUrl('pdf.offer.print', params);
        return this.http.post(url, { language: params.language, country: params.country, printType: params.printType });
    }

    private callGetTaskState(payload: any) {
        return this.getTaskState(payload).pipe(
            map((res) => {
                return this.checkState(res.state, payload);
            }),
            retryWhen((error) => {
                return error.pipe(delay(4000), take(15), (o) => concat(o, throwError('Retry limit exceeded!')));
            })
        );
    }

    private checkState(state, payload) {
        if (state === 'FINISHED_WITH_ERRORS' || state === 'TERMINATED' || state === 'FINISHED_ABORTED') {
            return { isError: true, url: '' };
        } else if (state === 'FINISHED') {
            return {
                isError: false,
                url: this.getPdfDownloadUrl({
                    documentId: payload.documentId,
                    latestRevision: payload.latestRevision,
                }),
            };
        } else {
            throw new Error();
        }
    }

    getAttachments(params): Observable<any> {
        const url = this.urlService.getUrl('next-steps.attachments', params);
        return this.http.get(url);
    }

    deleteAttachment(params): Observable<boolean> {
        const url = this.urlService.getUrl('next-steps.attachment.delete', params);
        return this.http.delete<boolean>(url).pipe(map(() => true));
    }

    downloadAttachment(params) {
        return this.urlService.getUrl('next-steps.attachment.download', params);
    }

    getQuoteEmailText(
        documentId: string,
        revision: string,
        language: string,
        country: string
    ): Observable<{ text: string }> {
        return this.http.get<{ text: string }>(
            `${environment.http.baseUrl}quote/${documentId}/revision/${revision}/mail-text`,
            {
                params: {
                    language,
                    country,
                },
            }
        );
    }

    getEmailRecipients(customerNumber: string) {
        return this.http.get(`${environment.http.companyUrl}${customerNumber}/contacts`);
    }

    public getAdditionalAttachments(language: string, country: string): Observable<AdditionalAttachment[]> {
        return this.http
            .get<AdditionalAttachment[]>(`${environment.http.baseUrl}offer/attachments`, {
                params: { language, country },
            })
            .pipe(map((attachments) => attachments.map((a) => ({ ...a, name: a.url.split('/').pop() }))));
    }

    public getExportTypes(salesOrg: string) {
        return this.http.get(`${environment.http.quotation}offers/export/types`, { params: { salesOrg } });
    }
}
