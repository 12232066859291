import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { NextStepsComponent } from './next-steps.component';
import { NextStepsRouting } from './next-steps.routing';

@NgModule({
    declarations: [NextStepsComponent],
    imports: [CommonModule, NextStepsRouting, SharedModule],
})
export class NextStepsModule {}
