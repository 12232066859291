import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, concatMap, delay } from 'rxjs/operators';
import { AppService } from '../_shared/services/app.service';
import { PermissionService } from '../_shared/services/permission.service';
import { ProductsCategoryService } from '../_shared/services/products-category.service';
import { ProductsService } from '../_shared/services/products.service';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { OverviewDataSource } from './overview-data-source';
import { OverviewDataService, SearchParams, UseTemplateParams } from './overview.data.service';
import { QuoteDiscountsService } from '../_shared/services/quote-discounts.service';
import { environment } from '../../environments/environment';
import { QuoteService } from '../_shared/services/quote.service';
import { openQuoteCreationDialog } from '../_shared/components/quote-creation-dialog/quote-creation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
    private isOffersLoading = true;
    private isTemplatesLoading = true;
    public loaderText: string;
    public overviewTableDataSource: OverviewDataSource<any, SearchParams>;
    public templatesDataSource: OverviewDataSource<any, SearchParams>;

    public selectedTab: 'offers' | 'templates' = 'offers';

    public foerderProfiUrl: string;

    constructor(
        private dataService: OverviewDataService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private productsService: ProductsService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private permissionService: PermissionService,
        private selectedCustomerService: SelectedCustomerService,
        private quoteDiscountsService: QuoteDiscountsService,
        public appService: AppService,
        private categoryService: ProductsCategoryService,
        private quoteService: QuoteService
    ) {}

    public ngOnInit() {
        this.appService.appTitle$.next('');
        this.handleRoute();
        this.foerderProfiUrl = environment.http.foerderProfiUrl;
        this.quoteService.reset();
        this.selectedCustomerService.clearSelectedCustomer();
        this.productsService.clearPriceSummaryCache();
        this.categoryService.clearCategoriesCache();

        this.setLoaderText('OVERVIEW.LOADER.LOADING_OFFERS');
        this.overviewTableDataSource = new OverviewDataSource<any, SearchParams>(
            this.appService,
            this.selectedCustomerService,
            (p) => this.dataService.searchOffers(p)
        );
        this.overviewTableDataSource.loading$.subscribe((loading) => {
            this.isOffersLoading = loading;
        });
        this.templatesDataSource = new OverviewDataSource<any, SearchParams>(
            this.appService,
            this.selectedCustomerService,
            (p) => this.dataService.searchTemplates(p)
        );
        this.templatesDataSource.loading$.subscribe((loading) => (this.isTemplatesLoading = loading));
        this.appService.salesOrg$.subscribe(() => {
            this.getOfferList();
            this.getTemplateList();
        });
    }

    handleRoute() {
        const firstChild = this.route.snapshot.children[0];
        if (firstChild.url.some(({ path }) => path === 'templates')) {
            this.selectedTab = 'templates';
            const documentId = firstChild.params.id;
            if (documentId) {
                this.dataService
                    .getQuote(documentId)
                    .pipe(
                        concatMap((quote) =>
                            openQuoteCreationDialog(this.dialog, {
                                title: this.translateService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.TEMPLATE_TITLE', {
                                    title: quote.title,
                                }),
                                confirm: this.translateService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.CONFIRM'),
                                cancel: this.translateService.instant('COMMON.DIALOG.CANCEL'),
                                description: this.translateService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.DESCRIPTION'),
                                templateDescription: this.translateService.instant(
                                    'OVERVIEW.DIALOG.USE_TEMPLATE.TEMPLATE_DESCRIPTION',
                                    {
                                        description: quote.customProperties.find((p) => p.key === 'templateDescription')
                                            ?.stringValue,
                                    }
                                ),
                            })
                        )
                    )
                    .subscribe(
                        (title) => {
                            if (title) {
                                this.onUseTemplate({ documentId, latestRevision: 0, title });
                            }
                        },
                        () => {
                            this.snackBarService.openSnackBar({
                                message: this.translateService.instant('OVERVIEW.DIALOG.USE_TEMPLATE.FAILURE_MSG'),
                                isFailure: true,
                            });
                        }
                    );
            }
        } else {
            this.selectedTab = 'offers';
        }
    }

    public get isPartnerMode() {
        return !!this.selectedCustomerService.customerForPartnerMode$?.value;
    }

    public get isEmployee() {
        return this.permissionService.isUserAnEmployee;
    }

    public get showTemplatesTab() {
        return this.appService.isAuthorizedToUseTemplates;
    }

    public get isLoading() {
        return this.selectedTab === 'offers' ? this.isOffersLoading : this.isTemplatesLoading;
    }

    public onViewOffer({ documentId, title, latestRevision, quickRefs, status, revisions, quoteId }) {
        const quickRef = quickRefs[0];
        this.quoteDiscountsService.clearQuoteDiscounts();

        if (status && status.toLowerCase() !== 'new') {
            this.router.navigate(['next-steps'], {
                queryParams: {
                    documentId,
                    latestRevision,
                    sapDocumentId: quoteId,
                    printType: revisions[0].printType.stringValue || undefined,
                },
            });
        } else {
            this.router.navigate(['configuration'], {
                queryParams: { documentId, title, latestRevision, quickRef },
            });
        }
    }

    public onDeleteOffer({ documentId, latestRevision, status, reason }) {
        this.isOffersLoading = true;
        this.deleteOffer({ documentId, latestRevision, status, reason });
    }

    private setLoaderText(key: string) {
        // use instant first to avoid visible key while translations are being fetched
        this.loaderText = this.translateService.instant(key);
        // this makes sure we use the right language right after selecting a language from dropdown
        this.translateService.get(key).subscribe((res) => (this.loaderText = res));
    }

    public onCopyOffer({ documentId, latestRevision, title }) {
        this.setLoaderText('OVERVIEW.LOADER.COPYING_OFFER');
        this.isOffersLoading = true;
        this.copyOffer({ documentId, latestRevision, title });
    }

    public onUseTemplate({ documentId, latestRevision, title }) {
        this.setLoaderText('OVERVIEW.LOADER.CREATING_OFFER');
        this.isTemplatesLoading = true;
        this.useTemplate({ documentId, revision: latestRevision, title });
    }

    public createNewOffer() {
        const title = this.translateService.instant('COMMON.NEW_OFFER');
        const salesAreaId = this.appService.getSalesAreaId();
        const language = this.appService.getLanguage();

        this.setLoaderText('OVERVIEW.LOADER.CREATING_OFFER');
        this.isOffersLoading = true;

        this.dataService.createNewOffer({ title, salesAreaId, language }).subscribe(
            (response) => {
                this.quoteDiscountsService.clearQuoteDiscounts();
                this.onSuccess('OVERVIEW.SNACK_BAR.CREATE_OFFER.SUCCESS');
                this.router.navigate(['configuration'], {
                    queryParams: {
                        // use empty string instead of response.title since we want to force the user to provide a non default title
                        title: '',
                        quickRef: response.quickRef,
                        latestRevision: response.revision,
                        documentId: response.documentId,
                    },
                });
            },
            () => this.onError('OVERVIEW.SNACK_BAR.CREATE_OFFER.FAILURE')
        );
    }

    public onSearch($event) {
        if (this.selectedTab === 'offers') {
            this.isOffersLoading = true;
            this.searchForOffers($event);
        } else {
            this.isTemplatesLoading = true;
            this.searchForTemplates($event);
        }
    }

    private deleteOffer(params) {
        (params.status.toLowerCase() === 'new'
            ? of(undefined)
            : this.dataService.cancelOffer(params.documentId, params.latestRevision, params.reason)
        )
            .pipe(
                concatMap(() => this.dataService.deleteOffer(params)),
                delay(100)
            )
            .subscribe(
                () => {
                    this.onSuccess('OVERVIEW.SNACK_BAR.DELETE_OFFER.SUCCESS');
                    this.getOfferList();
                },
                () => this.onError('OVERVIEW.SNACK_BAR.DELETE_OFFER.FAILURE')
            );
    }

    public getOfferList() {
        this.setLoaderText('OVERVIEW.LOADER.LOADING_OFFERS');
        this.overviewTableDataSource.resetPaging();
        this.overviewTableDataSource
            .loadOverviewData()
            .pipe(catchError(() => of(this.onError('OVERVIEW.SNACK_BAR.FETCH_OFFERS.FAILURE'))))
            .subscribe();
    }

    public getTemplateList() {
        this.templatesDataSource.resetPaging();
        this.templatesDataSource
            .loadOverviewData({ sortBy: 'title', sortOrder: 'asc', pageSize: 10 })
            .pipe(catchError(() => of(this.onError('OVERVIEW.SNACK_BAR.FETCH_TEMPLATES.FAILURE'))))
            .subscribe();
    }

    public onPagingError() {
        this.onError('OVERVIEW.SNACK_BAR.PAGING.FAILURE');
    }

    public onSortError() {
        this.onError('OVERVIEW.SNACK_BAR.SORTING.FAILURE');
    }

    public onCustomerSelected() {
        this.getOfferList();
    }

    private searchForOffers(parameters) {
        this.overviewTableDataSource.resetPaging();
        this.overviewTableDataSource
            .loadOverviewData({ parameters })
            .pipe(catchError(() => of(this.onError('OVERVIEW.SNACK_BAR.SEARCH_OFFER.FAILURE'))))
            .subscribe();
    }

    private searchForTemplates(parameters) {
        this.templatesDataSource.resetPaging();
        this.templatesDataSource
            .loadOverviewData({ parameters, sortBy: 'title', sortOrder: 'asc', pageSize: 10 })
            .pipe(catchError(() => of(this.onError('OVERVIEW.SNACK_BAR.SEARCH_TEMPLATE.FAILURE'))))
            .subscribe();
    }

    private copyOffer(params) {
        this.dataService
            .copyOffer(params)
            // load quote via graphql to get accurate meta data. response of quote graphql contains outdated information
            .pipe(concatMap((response: { documentId: string }) => this.dataService.getQuote(response.documentId)))
            .subscribe(
                (response) => {
                    this.onSuccess('OVERVIEW.SNACK_BAR.COPY_OFFER.SUCCESS');
                    this.navigateToConfiguration(response);
                },
                () => this.onError('OVERVIEW.SNACK_BAR.COPY_OFFER.FAILURE')
            );
    }

    private useTemplate(params: UseTemplateParams) {
        this.dataService.useTemplate(params).subscribe(
            (response) => {
                this.onSuccess('OVERVIEW.SNACK_BAR.USE_TEMPLATE.SUCCESS');
                this.navigateToConfiguration(response);
            },
            () => this.onError('OVERVIEW.SNACK_BAR.USE_TEMPLATE.FAILURE')
        );
    }

    private navigateToConfiguration(response) {
        const qref: string = response.quickRef || (response.quickRefs && response.quickRefs[0]);
        const revision = response.revision !== undefined ? response.revision : response.latestRevision;
        this.quoteDiscountsService.clearQuoteDiscounts();
        this.router.navigate(['configuration'], {
            queryParams: {
                documentId: response.documentId,
                title: response.title,
                quickRef: qref,
                latestRevision: revision,
            },
        });
    }

    private onError(translationKey: string) {
        this.isOffersLoading = false;
        this.isTemplatesLoading = false;
        if (this.permissionService.isUserAuthorized$.value) {
            setTimeout(() => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant(translationKey),
                    isFailure: true,
                });
            }, 200);
        }
    }

    private onSuccess(translationKey: string) {
        this.snackBarService.openSnackBar({
            message: this.translateService.instant(translationKey),
        });
    }
}
