import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ConfigitQuote } from '../../services/quote.service';

@Component({
    selector: 'app-valid-to-icon',
    templateUrl: './valid-to-icon.component.html',
    styleUrls: ['./valid-to-icon.component.scss'],
})
export class ValidToIconComponent implements OnInit {
    @Input() public quote: ConfigitQuote;
    public displayValidToHint = false;
    public validUntilDays: number;

    constructor() {}

    public ngOnInit() {
        const validTo = moment.utc(this.quote.validTo, 'YYYY-MM-DD').startOf('day').add(1, 'day');
        const diff = validTo.diff(moment.utc().startOf('day'), 'd');

        this.displayValidToHint = this.quote.status === 'Quoted' && diff > 0 && diff <= 14;
        this.validUntilDays = diff;
    }
}
