import { Injectable } from '@angular/core';
import { HttpService } from '../_shared/services/http.service';
import { UrlService } from '../_shared/services/url.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SummaryDataService {
    constructor(private urlService: UrlService, private httpService: HttpService) {}

    public closeQuoteInSap(params, payload): any {
        const url = this.urlService.getUrl('quote.close', params);
        return this.httpService.post(url, payload);
    }

    public getAvailablePrintTypes(salesOrg: string) {
        const url = `${environment.http.baseUrl}quote/printTypes?salesOrg=${salesOrg}`;
        return this.httpService.get(url);
    }
}
