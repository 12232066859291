import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private storedRouteHandles = new Map<string, DetachedRouteHandle>();

    public canReuseRoute = true;

    constructor() {}
    shouldDetach(_: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getRouteKey(route), handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRouteHandles.has(this.getRouteKey(route));
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRouteHandles.get(this.getRouteKey(route)) || null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig && this.canReuseRoute;
    }

    private getRouteKey(route: ActivatedRouteSnapshot): string {
        // Generate a unique key for the route based on your criteria
        // This example uses the route's path as the key
        return route.routeConfig?.path || '';
    }
}
