<div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px" class="loading">
    <div class="progress-spinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <div class="message" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
        <div class="message-loading">{{ 'COMMON.LOADER.MESSAGE_LOADING' | translate }}</div>
        <div data-ut-loader-message>
            {{ message }}
        </div>
    </div>
</div>
