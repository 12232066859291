import { TranslateService } from '@ngx-translate/core';
import { ConfigitQuestModelTransform } from './configit-quest-model-transform';
import { ConfigitItemsObj, ConfigitQuestSettings } from './configit-types.interface';

export class PackerConfigitQuestModelTransform extends ConfigitQuestModelTransform {
    protected productListProp = 'ProductList'; // can be set in env

    constructor(config?: ConfigitQuestSettings, translate?: TranslateService) {
        super(config, translate);
    }

    /**
     * Transforms custom data.
     */
    protected transformCustom(_variables: ConfigitItemsObj, states: ConfigitItemsObj): any {
        const productList = states[this.productListProp] && this.transformOptions(states[this.productListProp]);

        return { productList };
    }
}
