import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherType } from '../../../services/voucher.service';
import { TranslateService } from '@ngx-translate/core';

export interface PriceSummaryDialogData {
    quoteId: string;
    totalGross: number;
    totalDiscount: number;
    totalPrice: number;
    netPrice: number;
    extraBonus: number;
    voucherCode: string;
    voucherType: string;
    voucherValue: number;
    currency: string;
    noPriceAdvantage: boolean;
}

@Component({
    selector: 'app-price-summary-dialog',
    templateUrl: './price-summary-dialog.component.html',
    styleUrls: ['./price-summary-dialog.component.scss'],
})
export class PriceSummaryDialogComponent {
    constructor(
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: PriceSummaryDialogData
    ) {}

    public get isKamVoucher() {
        return this.data?.voucherType === VoucherType.kam;
    }

    public get voucherTypeKey() {
        return this.isKamVoucher ? VoucherType.kam : 'DEFAULT';
    }

    public get partnerShopTranslationAvailable() {
        const translation = this.translateService.instant('PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP');
        return translation !== '' && translation !== 'PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP';
    }
}
