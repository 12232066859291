<h3 data-at-category-name class="group-name">
    {{ translationKey | translate }}
</h3>
<div class="product-list-wrapper">
    <div *ngFor="let prod of products">
        <div data-at-product class="product-wrapper">
            <div>
                <span data-at-product-quanity>{{ prod.quantity }}</span>
                <span class="multiply-symbol">x</span>
                <span data-at-product-material>{{ prod.material }}</span>
            </div>

            <div data-at-product-name class="name-label">{{ chooseTranslation(prod.translation) }}</div>
        </div>
    </div>
</div>
