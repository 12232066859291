import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerSearchService } from 'src/app/_shared/services/customer-search.service';
// tslint:disable-next-line:max-line-length
import { CustomerSearchDialogComponent } from 'src/app/_shared/components/customer-search-dialog/customer-search-dialog.component';
import { SelectedCustomerService } from 'src/app/_shared/services/selected-customer.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface CustomerSelection {
    number: string;
    name?: string;
    calculationSchema?: string;
}

@Component({
    selector: 'app-customer-selection',
    templateUrl: './customer-selection.component.html',
    styleUrls: ['./customer-selection.component.scss'],
})
export class CustomerSelectionComponent implements OnInit, OnDestroy {
    @Input()
    public showClearButton = true;

    @Input() public partnerMode = false;

    @Input() public buttonText = 'CONFIGURATION.CUSTOMER_SEARCH.BTN.SELECT_CUSTOMER';

    @Output() public customerChanged: EventEmitter<void> = new EventEmitter<void>();

    public selectedCustomerName: string;
    private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public selectedCustomerService: SelectedCustomerService,
        private dialog: MatDialog,
        private customerSearchService: CustomerSearchService
    ) {}

    public ngOnInit() {
        const subject = this.partnerMode
            ? this.selectedCustomerService.customerForPartnerMode$
            : this.selectedCustomerService.selectedCustomer$;
        subject.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((v) => {
            if (v === undefined || v.number === '' || v.number === undefined) {
                this.selectedCustomerName = undefined;
                return;
            }

            if (v.name === undefined) {
                this.selectedCustomerName = v.number;
                this.customerSearchService.findCustomers(v.number).subscribe((customers) => {
                    const detailedCustomer = customers.find((customer) => customer.number === v.number);
                    if (detailedCustomer) {
                        this.selectedCustomerName = detailedCustomer.name;
                        subject.next(detailedCustomer);
                    }
                });
            } else {
                this.selectedCustomerName = v.name;
            }
        });
    }

    public clearSelectedCustomer(): void {
        if (this.partnerMode) {
            this.selectedCustomerService.customerForPartnerMode$.next(undefined);
            this.customerChanged?.emit();
        } else {
            this.openCustomerSearchDialog();
        }
    }

    public openCustomerSearchDialog(): void {
        const dialogRef = this.dialog.open(CustomerSearchDialogComponent, {
            data: {
                partnerMode: this.partnerMode,
            },
        });
        if (this.customerChanged) {
            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.ngUnsubscribe$), filter(Boolean))
                .subscribe(() => {
                    this.customerChanged.emit();
                });
        }
    }

    public ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.unsubscribe();
    }
}
