<mat-table matSortActive="title" matSortDirection="asc" class="overview-table" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.TITLE' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj">
            <div class="cell-spacing">
                <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.TITLE' | translate }}:</span>
                <span>{{ obj.title }}</span>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="schemePreview">
        <mat-header-cell *matHeaderCellDef>
            {{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.SCHEME' | translate }}
        </mat-header-cell>
        <mat-cell
            *matCellDef="let obj"
            (mouseenter)="onImageHover($event, obj)"
            (mousemove)="onImageHover($event, obj)"
            (mouseleave)="onImageHover($event, obj)"
        >
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.SCHEME' | translate }}:</span>
            <a [href]="obj.schemeUrl" target="_blank" *ngIf="obj.schemeUrl && obj.schemePreview">
                <img [src]="obj.schemePreview" />
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.DESCRIPTION' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj">
            <div class="cell-spacing">
                <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.TEMPLATES.DESCRIPTION' | translate }}:</span>
                <span>{{ obj.description }}</span>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'OVERVIEW.TABLE.COLUMN.CREATED_AT' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let obj">
            <span class="mobile-label">{{ 'OVERVIEW.TABLE.COLUMN.CREATED_AT' | translate }}:</span>
            <span>{{ obj.createdDate | dateFormat: 'dotDate' }}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let obj">
            <div class="sm-screen-btns">
                <button mat-button color="primary" (click)="onSelectTemplate(obj)">
                    <mat-icon class="material-symbols-outlined">edit_document</mat-icon>
                    {{ 'OVERVIEW.ACTIONS.TEMPLATES.USE' | translate }}
                </button>
            </div>
            <div class="lg-screen-btns">
                <button
                    mat-icon-button
                    color="primary"
                    (click)="onSelectTemplate(obj)"
                    matTooltip="{{ 'OVERVIEW.ACTIONS.TEMPLATES.USE' | translate }}"
                >
                    <mat-icon class="material-symbols-outlined">edit_document</mat-icon>
                </button>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
</mat-table>

<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>

<div *ngIf="!dataSource?.hasData()" fxLayout="row" fxLayoutAlign="center center">
    <p data-at-no-results>{{ 'COMMON.NO_RESULTS' | translate }}</p>
</div>
