<div class="dialog-container">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <p [innerHTML]="data.question"></p>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button [mat-dialog-close]="false">{{ data.no }}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ data.yes }}</button>
    </div>
</div>
