import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Reason {
    value: string;
    displayValue: string;
}

export class QuoteDeleteDialogData {
    public title: string;
    public question: string;
    reasons$?: Subject<{ [key: string]: string }>;
    public no: string;
    public yes: string;
}

export function openQuoteDeleteDialog(
    dialog: MatDialog,
    title: string,
    question: string,
    yes: string,
    no: string,
    reasons$?
) {
    const dialogRef = dialog.open(QuoteDeleteDialogComponent, {
        autoFocus: false,
        data: {
            title,
            question,
            reasons$,
            yes,
            no,
        },
    });
    return dialogRef.afterClosed();
}

@Component({
    selector: 'app-quote-delete-dialog',
    templateUrl: './quote-delete-dialog.component.html',
    styleUrls: ['./quote-delete-dialog.component.scss'],
})
export class QuoteDeleteDialogComponent implements OnInit {
    public availableReasons$: Observable<Reason[]>;
    public reason: Reason;

    constructor(@Inject(MAT_DIALOG_DATA) public data: QuoteDeleteDialogData) {}

    ngOnInit(): void {
        if (this.data.reasons$) {
            this.availableReasons$ = this.data.reasons$.pipe(
                map((reasons) => Object.keys(reasons).map((key) => ({ value: key, displayValue: reasons[key] })))
            );
        }
    }
}
