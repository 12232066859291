<app-card [isFooter]="true" [isHeader]="true" class="price-summary-card">
    <div header class="summary-card-header">
        <h2 class="title">{{ 'CONFIGURATION.PANEL.TITLE.TOTAL' | translate }}</h2>
        <div class="header-content" *ngIf="isSummaryPage">
            <mat-checkbox
                [checked]="quoteNoPriceAdvantage"
                (change)="setNoPriceAdvantage($event)"
                *ngIf="noPriceAdvantageCheckboxAvailable"
                >{{ 'SUMMARY.NO_PRICE_ADVANTAGE.LABEL' | translate }}</mat-checkbox
            >
            <div class="header-content-voucher">
                <form
                    [formGroup]="group"
                    *ngIf="
                        hasConfiguration &&
                        appService.getLocation().toUpperCase() === 'DE' &&
                        (selectedCustomerService.isPlannerOrArchitect$ | async) === false
                    "
                >
                    <ng-container *ngIf="group.get('voucherCode') as control">
                        <mat-form-field
                            style="font-size: 14px; margin-right: 20px"
                            [class.disabled-state]="
                                validationInProgress || (dataService.isPriceSummaryLoading$ | async)?.value
                            "
                        >
                            <mat-icon matPrefix>edit</mat-icon>
                            <input
                                matInput
                                [formControl]="asFormControl(control)"
                                [errorStateMatcher]="dirtyMatcher"
                                placeholder="{{ 'SUMMARY.VOUCHER_CODE_INPUT' | translate }}"
                            />
                            <mat-hint class="hint-ok" *ngIf="control.valid && control.value">{{
                                'OK' | translate
                            }}</mat-hint>
                            <mat-error *ngIf="control.hasError('validationError')">{{ errorMessage }}</mat-error>
                            <mat-error *ngIf="control.hasError('noPriceAdvantageCheckboxError')">{{
                                'SUMMARY.VOUCHER_CODE_ERROR.NO_PRICE_ADVANTAGE_CHECKBOX' | translate
                            }}</mat-error>
                            <mat-error *ngIf="control.hasError('serverError')">{{
                                'SUMMARY.VOUCHER_CODE_ERROR.SERVER_ERROR' | translate
                            }}</mat-error>

                            <mat-progress-bar mode="indeterminate" *ngIf="control.pending"></mat-progress-bar>
                        </mat-form-field>
                    </ng-container>
                </form>
                <button
                    mat-raised-button
                    color="primary"
                    class="discount-dialog-button"
                    (click)="openPartnerDiscountDialog()"
                    *ngIf="showDiscountButton()"
                    [disabled]="isVoucherSet()"
                >
                    {{ 'QUOTE_DISCOUNT_DIALOG.BUTTON' | translate }}
                </button>
            </div>
        </div>
        <div class="hint">
            <vi-quest-hint
                *ngIf="!isSummaryPage && displayPriceAdvantage$ | async"
                [model]="{ text: 'CONFIGURATION.PANEL.PRICE_TOOLTIP' | translate, important: false }"
            >
            </vi-quest-hint>
        </div>
    </div>

    <div body>
        <div *ngIf="!isSummaryPage && showPartnerSelectionHint">
            <div class="warning">{{ 'CONFIGURATION.PANEL.HINT.PARTNER_SELECTION' | translate }}</div>
        </div>
        <div *ngIf="!isSummaryPage && !showPartnerSelectionHint">
            <ng-container *ngIf="displayPriceAdvantage$ | async">
                <div class="flex-space-between-baseline">
                    <div class="small label">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
                    <div [attr.data-at-price-gross]="totalGross" class="value">
                        {{ totalGross | currency: currency:'symbol-narrow' }}
                    </div>
                </div>
                <div class="flex-space-between-baseline">
                    <div class="small label">
                        {{ 'CONFIGURATION.PANEL.LABEL.PACKAGE_PRICE_ADVANTAGE' | translate }}:
                    </div>
                    <div [attr.data-at-discount]="discount" class="value discount">
                        - {{ discount | currency: currency:'symbol-narrow' }}
                    </div>
                </div>
                <div class="flex-space-between-baseline">
                    <div class="small label total">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_PRICE' | translate }}:</div>
                    <div [attr.data-at-price-total]="totalPrice" class="value total">
                        {{ totalPrice | currency: currency:'symbol-narrow' }}
                    </div>
                </div>
            </ng-container>
            <div class="flex-space-between-baseline" *ngIf="(displayPriceAdvantage$ | async) === false">
                <div class="small label total">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
                <div [attr.data-at-price-total]="totalPrice" class="value total">
                    {{ totalGross | currency: currency:'symbol-narrow' }}
                </div>
            </div>
        </div>

        <div *ngIf="isSummaryPage">
            <div class="flex-space-between-baseline">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
                <div [attr.data-at-price-gross]="totalGross" class="value">
                    {{ totalGross | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div class="flex-space-between-baseline" *ngIf="displayPriceAdvantage$ | async">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.PACKAGE_PRICE_ADVANTAGE' | translate }}:</div>
                <div [attr.data-at-discount]="discount" class="value discount">
                    - {{ discount | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div class="flex-space-between-baseline" *ngIf="displayPriceAdvantage$ | async">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_PRICE' | translate }}:</div>
                <div [attr.data-at-price-total]="totalPrice" class="value">
                    {{ totalPrice | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div class="flex-space-between-baseline" class="space"></div>
            <div
                *ngIf="(selectedCustomerService.isPlannerOrArchitect$ | async) === false"
                class="flex-space-between-baseline"
            >
                <div class="label light-total">{{ 'CONFIGURATION.PANEL.LABEL.NET_PRICE' | translate }}:</div>
                <div [attr.data-at-price-net]="netPrice" class="value light-total">
                    {{ netPrice | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div class="flex-space-between-baseline">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.VAT' | translate }}:</div>
                <div [attr.data-at-price-vat]="vat" class="value">
                    {{ vat | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div class="flex-space-between-baseline">
                <div class="label total">{{ 'CONFIGURATION.PANEL.LABEL.SUM_PRICE' | translate }}:</div>
                <div [attr.data-at-price-sum]="sum" class="value total">
                    {{ sum | currency: currency:'symbol-narrow' }}
                </div>
            </div>
        </div>

        <!-- Info Boxes -->
        <ng-container *ngIf="isSummaryPage">
            <ng-container *ngIf="currentVoucher">
                <div class="info-text-container" *ngIf="isKamVoucher && isCustomer">
                    <mat-icon class="info-text-icon">info</mat-icon>
                    <div class="info-text-content">
                        <span>{{ 'CONFIGURATION.PANEL.LABEL.VOUCHER.NO_PRICE_ADVANTAGE' | translate }}</span>
                    </div>
                </div>
                <div class="info-text-container" *ngIf="voucherValue || extraBonus">
                    <mat-icon class="info-text-icon">info</mat-icon>
                    <div class="info-text-content">
                        <span>
                            {{
                                'PRICE_SUMMARY.INFO_TEXT.VOUCHER.' + voucherTypeKey
                                    | translate: { voucherCode: currentVoucher.toLowerCase() }
                            }}
                        </span>
                        <span *ngIf="voucherValue">
                            {{
                                'PRICE_SUMMARY.INFO_TEXT.VOUCHER.VOUCHER_VALUE.' + voucherTypeKey
                                    | translate: { voucherValue: voucherValue | currency: currency:'symbol-narrow' }
                            }}
                        </span>
                        <span *ngIf="extraBonus">{{
                            'PRICE_SUMMARY.INFO_TEXT.VOUCHER.EXTRA_BONUS'
                                | translate: { extraBonus: extraBonus | currency: currency:'symbol-narrow' }
                        }}</span>
                    </div>
                </div>
            </ng-container>
            <div class="info-text-container" *ngIf="displayPriceAdvantage$ | async">
                <mat-icon class="info-text-icon">info</mat-icon>
                <div class="info-text-content">
                    <span>
                        {{ 'PRICE_SUMMARY.INFO_TEXT.PRICE_ADVANTAGE' | translate }}
                    </span>
                </div>
            </div>
            <div class="info-text-container" *ngIf="partnerShopTranslationAvailable">
                <mat-icon class="info-text-icon">info</mat-icon>
                <div class="info-text-content">
                    <span>
                        {{ 'PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP' | translate }}
                    </span>
                </div>
            </div>
        </ng-container>
    </div>

    <div footer>
        <mat-progress-bar
            mode="indeterminate"
            *ngIf="(dataService.isPriceSummaryLoading$ | async)?.value"
        ></mat-progress-bar>
    </div>
</app-card>
