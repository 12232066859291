<div class="dialog-container">
    <h1 mat-dialog-title [innerHTML]="data.title"></h1>
    <div class="description" *ngIf="data.templateDescription" [innerHTML]="data.templateDescription"></div>
    <div class="description" *ngIf="data.description" [innerHTML]="data.description"></div>
    <div mat-dialog-content>
        <div>
            <mat-label>{{ 'CONFIGURATION.OFFER_TITLE_PLACEHOLDER' | translate }}</mat-label>
            <mat-form-field fxFlex class="bordered-field" appearance="outline">
                <input matInput maxlength="100" [(ngModel)]="offerTitle" required />
                <mat-hint align="end">{{ offerTitle.length }} / 100</mat-hint>
                <mat-error> {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button [mat-dialog-close]="false" color="secondary">{{ data.cancel }}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="offerTitle" [disabled]="!offerTitle">
            {{ data.confirm }}
        </button>
    </div>
</div>
