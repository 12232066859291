import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-scheme-preview',
    templateUrl: './scheme-preview.component.html',
    styleUrls: ['./scheme-preview.component.scss'],
})
export class SchemePreviewComponent {
    @Input()
    public primaryScheme: string;

    constructor() {}

    public schemeDownloadUrl(schemeId: string, preview?: boolean) {
        const params = new HttpParams({
            fromObject: {
                utm_source: 'quote-assist',
                ...(preview && { type: 'preview'})
            },
        });
        return `${environment.http.pim}schemes/${schemeId}?${params.toString()}`;
    }
}
