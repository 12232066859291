import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * Base class for api services - covering path transformations and handling request/response staff.
 * Extensible by specialized api services - having configured base url and paths related methods
 *
 * @example
 *
 * ```ts
 * // in exmaple.service.ts
 * import { Injectable } from '@angular/core';
 * import { ApiBase } from '../api';
 *
 * @Injectable()
 * export class ExampleApiService extends ApiBase {
 *
 *   protected baseUrl: string = '/test';
 *
 *   get(data: any) {
 *     return this.request('post', '/something/{id}', data);
 *   }
 * }
 */
@Injectable()
export class ApiBase {
    protected baseUrl: string;

    constructor(protected http: HttpClient, protected translate: TranslateService) {}

    /**
     * Request wrapper
     */
    public request(method: string, url: string, data?: any): Observable<any> {
        const body = data && Object.assign({}, data);
        const path = this.baseUrl + this.path(url, body);

        return this.http.request(method, path, { body });
    }

    /**
     * Path transformer - replaces {some}/{patterns} within data.patterns and data.some properties
     *
     * @note Path properties are removed from data
     */
    protected path(url: string, data?: any, remove: boolean = true): string {
        const params = url.match(/{\w+}/g);

        if (params && !data) {
            throw Error('Data required for path ' + url);
        }

        (params || []).forEach((param: string) => {
            const property = param.substring(1, param.length - 1);
            let value = data[property];

            if (value === undefined) {
                console.warn('Property ' + property + ' missing for path ' + url); // tslint:disable-line no-console
                value = '';
            }
            url = url.replace(param, value);
            if (remove) {
                delete data[property];
            }
        });
        return url;
    }
}
