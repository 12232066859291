<mat-card appearance="outlined" data-ut-card [class.dialog]="isDialog">
    <mat-card-title data-ut-card-title *ngIf="isHeader" [class.header]="isHeader">
        <ng-content select="[header]"></ng-content>
    </mat-card-title>
    <mat-card-content data-ut-card-body>
        <ng-content select="[body]"></ng-content>
    </mat-card-content>
    <mat-card-footer data-ut-card-footer *ngIf="isFooter" fxLayout="row" fxLayoutAlign="end center">
        <ng-content select="[footer]"></ng-content>
    </mat-card-footer>
</mat-card>
