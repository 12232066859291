import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OverviewComponent } from './overview.component';
import { authGuard } from '../_shared/guards/auth.guard';
import { maintenanceGuard } from '../_shared/guards/maintenance.guard';

const overviewRoutes: Routes = [
    {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [authGuard, maintenanceGuard],
        data: {
            canChangeLocation: true,
        },
        children: [
            {
                path: 'quotes',
                component: OverviewComponent,
            },
            {
                path: 'templates/use/:id',
                component: OverviewComponent,
            },
            {
                path: 'templates',
                component: OverviewComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(overviewRoutes)],
    exports: [RouterModule],
})
export class OverviewRouting {}
