<app-main-content content>
    <div main fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="externalApp && callbackUrl">
        <a mat-icon-button [href]="callbackUrl">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <a class="back-to-external-app" [href]="callbackUrl">
            {{ 'SUMMARY.EXTERNAL_APP.CALLBACK_URL' | translate }}
        </a>
    </div>
    <div main fxLayout="row" fxLayoutGap="10px" *ngIf="!callbackUrl">
        <button
            mat-icon-button
            [routerLink]="hasConfiguration ? '/configuration' : ['../', 'planning-project', salesforceIdForPlanning]"
            [queryParams]="navigationParams"
        >
            <mat-icon>arrow_back</mat-icon>
        </button>
        <app-breadcrumbs
            formerPage="{{
                (hasConfiguration ? 'COMMON.BREADCRUMBS.CONFIGURATION' : 'COMMON.BREADCRUMBS.PLANNING_PROJECT')
                    | translate
            }}"
            currentPage="{{ 'COMMON.BREADCRUMBS.SUMMARY' | translate }}"
            [navigateTo]="hasConfiguration ? '/configuration' : ['../', 'planning-project', salesforceIdForPlanning]"
            [queryParams]="navigationParams"
        ></app-breadcrumbs>
    </div>

    <div class="top-container" fxLayout="row" main fxLayoutAlign="space-between center">
        <h2 class="header-title">{{ 'SUMMARY.TITLE' | translate }}</h2>
        <mat-form-field *ngIf="salesforceIdControl">
            <input
                matInput
                type="text"
                name="salesforceId"
                [formControl]="salesforceIdControl"
                [placeholder]="'SUMMARY.SALESFORCE_ID.PLACEHOLDER' | translate"
            />
            <mat-icon matPrefix>edit</mat-icon>
        </mat-form-field>
        <app-customer-selection
            [showClearButton]="externalApp"
            class="customer-selection-container"
            *ngIf="permissionService.isUserAnEmployee && !isLoading"
        ></app-customer-selection>
    </div>

    <div *ngIf="!isLoading" main fxLayout="column" fxLayoutGap="10px" class="main-container">
        <app-product-table [dataSource]="dataSource"></app-product-table>
        <app-scheme-preview *ngIf="primaryScheme" [primaryScheme]="primaryScheme"></app-scheme-preview>
        <app-price-summary
            fxFlex
            [dataSource]="dataSource"
            [isSummaryPage]="true"
            (voucherCodeChanged)="updateVoucherCode($event)"
        ></app-price-summary>
    </div>

    <app-card main *ngIf="!isLoading && availablePrintTypes?.length" [isHeader]="false" [isFooter]="false">
        <div body fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="50">
                <mat-label id="printType">
                    {{ 'SUMMARY.PRINT_TYPE.LABEL' | translate }}
                    <vi-quest-hint
                        [model]="{ text: 'SUMMARY.PRINT_TYPE.HINT' | translate, important: false }"
                    ></vi-quest-hint>
                </mat-label>
                <mat-radio-group
                    aria-labelledby="printType"
                    [(ngModel)]="printType"
                    fxLayoutAlign="space-between center"
                >
                    <mat-radio-button *ngFor="let type of availablePrintTypes" [value]="type">
                        {{ 'SUMMARY.PRINT_TYPE.TYPES.' + type | translate }}</mat-radio-button
                    >
                </mat-radio-group>
            </div>
            <button
                mat-raised-button
                color="primary"
                [disabled]="
                    (priceSummaryIsLoading() | async)?.value ||
                    (priceSummaryHasErrors() | async)?.value ||
                    !selectedCustomerId
                "
                (click)="closeOffer()"
            >
                <mat-icon>save</mat-icon>
                {{ 'SUMMARY.BUTTON.COMPLETE_CONFIGURATION' | translate }}
            </button>
        </div>
    </app-card>

    <div *ngIf="!isLoading" main fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <ng-container *ngIf="!externalApp">
            <button mat-button (click)="navigateBackToConfiguration()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'SUMMARY.BUTTON.BACK_TO_CONFIGURATION' | translate }}
            </button>
            <span class="hint" [innerHTML]="'SUMMARY.HINT.COMPLETE_CONFIGURATION' | translate"></span>
        </ng-container>
        <a mat-button [routerLink]="['../', 'planning-project', salesforceIdForPlanning]" *ngIf="planning">
            <mat-icon>arrow_back</mat-icon>
            {{ 'SUMMARY.BUTTON.PLANNING.BACK_TO_PROJECT_OVERVIEW' | translate }}</a
        >
    </div>
    <app-loader main *ngIf="isLoading" [message]="'SUMMARY.LOADER.FINALIZE_OFFER' | translate"></app-loader>
</app-main-content>
