<div class="customer-search-container">
    <h2 class="dialog-title">{{ 'CUSTOMER_SEARCH_DIALOG.TITLE' | translate }}</h2>
    <div fxLayout="row" fxLayoutAlign="start bottom">
        <mat-form-field fxFlex class="search-field-container">
            <input
                data-at-search-input
                matInput
                [placeholder]="'CONFIGURATION.CUSTOMER_SEARCH.SEARCH_BY_KEYWORD_PLACEHOLDER' | translate"
                [(ngModel)]="searchCustomerValue"
                (keyup.enter)="onCustomerSearch()"
            />
            <button
                *ngIf="searchCustomerValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchCustomerValue = ''"
            >
                <mat-icon>close</mat-icon>
            </button>
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
        <button mat-button color="primary" [disabled]="isLoading" (click)="onCustomerSearch()">
            {{ 'COMMON.SEARCH' | translate }}
        </button>
    </div>
    <h5 class="search-results-label">{{ 'COMMON.SEARCH_RESULTS' | translate }}:</h5>
    <div class="search-results-container">
        <div *ngIf="isLoading">
            <app-loader [message]="'CONFIGURATION.CUSTOMER_SEARCH.LOADER_TEXT' | translate"></app-loader>
        </div>
        <ng-container *ngIf="customerSearchResult">
            <div class="customer-entry" fxLayout="row" *ngFor="let cust of customerSearchResult">
                <div fxFlex>
                    <div class="name">
                        {{ cust.name }}
                    </div>
                    <div class="address">
                        <div>
                            {{ cust.street }}
                        </div>
                        <div>
                            {{ cust.city }}
                        </div>
                    </div>
                    <button mat-button color="primary" class="select-btn" (click)="selectCustomer(cust)">
                        {{ 'CONFIGURATION.CUSTOMER_SEARCH.BTN.SELECT_CUSTOMER' | translate }}
                    </button>
                </div>
                <div class="customer-number-container">
                    <div>
                        {{ cust.number }}
                    </div>
                    <div>
                        {{ cust.industryKey }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="customerSearchResult?.length === 0 && searchCustomerValue" fxLayout="row" fxLayoutAlign="center center">
        <p data-at-no-results>{{ 'COMMON.NO_RESULTS_FOR' | translate }} "{{ searchCustomerValue }}"</p>
    </div>
    <div class="footer" mat-dialog-actions fxLayout="row">
        <div fxFlex fxLayout="row" fxLayoutAlign="end start">
            <button mat-button color="primary" (click)="closeDialog()">
                {{ 'COMMON.DIALOG.CANCEL' | translate }}
            </button>
        </div>
    </div>
</div>
