<div>
    <button mat-button class="trigger-button" [matMenuTriggerFor]="menu1">
        <span>{{ selectedLanguage }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu1 overlapTrigger="false">
        <button *ngFor="let locale of availableOptions" (click)="askAndSelectLocale(locale)" mat-menu-item>
            <span class="flag flag-{{ locale.flag }}"></span
            ><span> {{ 'LANGUAGE_DROPDOWN.' + locale.locale.replace('-', '_').toUpperCase() | translate }}</span>
        </button>
    </mat-menu>
</div>
