import { Component } from '@angular/core';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
    public isLite: boolean;

    constructor() {
        this.isLite = history.state?.isLite ? history.state?.isLite : false;
    }
}
