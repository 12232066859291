import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';

export interface EnvironmentDiff {
    added: {
        'CATEGORY-BDIS'?: {
            [key: string]: number;
        };
    };
}

export interface CategoriesCache {
    categories: Array<CachedDiscountCategory>;
}

export interface CachedDiscountCategory {
    category: string;
    discount: number;
}

@Injectable({
    providedIn: 'root',
})
export class ProductsCategoryService {
    constructor(private httpService: HttpService) {}
    private categoriesCache: CategoriesCache = {
        categories: [],
    };

    public haveDiscountCategoriesChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public updateProductCategoryDiscountsInCache(...envDiffs: EnvironmentDiff[]) {
        const categoryDiffs = envDiffs.filter((diff) => !!diff?.added['CATEGORY-BDIS']);
        if (categoryDiffs.length) {
            const before = JSON.stringify(this.categoriesCache.categories);
            this.categoriesCache.categories = categoryDiffs
                .map((envDiff) =>
                    Object.entries(envDiff.added['CATEGORY-BDIS']).map(([category, discount]) => ({
                        category,
                        discount,
                    }))
                )
                .reduce((prev, curr) => [...prev, ...curr]);
            const after = JSON.stringify(this.categoriesCache.categories);

            if (before !== after) {
                this.haveDiscountCategoriesChanged.next(true);
            }
        }
    }

    public getProductDiscountCategoriesFromCache() {
        return this.categoriesCache.categories;
    }

    public clearCategoriesCache() {
        this.categoriesCache.categories = [];
    }

    public fetchProductCategoryGroupNameMap(salesOrg: string) {
        const params: HttpParams = environment.app.productCategoryGroupNameMapSalesOrg.includes(salesOrg)
            ? new HttpParams({
                  fromObject: {
                      usecase: 'qa-ch',
                  },
              })
            : undefined;

        const url = `${environment.http.configuration}material/groupMap`;
        return this.httpService.get(url, params);
    }
}
