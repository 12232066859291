import { Component, Input } from '@angular/core';
import { AppService, ProductTranslations } from 'src/app/_shared/services/app.service';

@Component({
    selector: 'app-product-group',
    templateUrl: './product-group.component.html',
    styleUrls: ['./product-group.component.scss'],
})
export class ProductGroupComponent {
    @Input()
    public products: Array<any>;
    @Input()
    public translationKey: string;

    constructor(public appService: AppService) {}

    public chooseTranslation(productTranslations?: ProductTranslations) {
        return this.appService.chooseTranslation(productTranslations);
    }
}
