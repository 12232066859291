import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

type QuoteStatus = 'New' | 'Quoted';
export enum PlanningType {
    heatpump = 'HEAT_PUMP_PLANNER',
    photovoltaic = 'PHOTOVOLTAIC_PLANNER',
}

export interface SalesforceQuoteResponse {
    totalCount: number;
    items: {
        title: string;
        documentId: string;
        revision: string;
        quickRef: string;
        status: QuoteStatus;
        createdDate: string;
        salesDocumentNumber: string;
        owner: {
            id: string;
            name: string;
        };
        salesAreaId: string;
        salesAreaName: string;
        externalApp: string;
        salesforceId: string;
        projectIdSF: string;
        planningId: string;
        planningType: 'HEAT_PUMP_PLANNER' | 'PHOTOVOLTAIC_PLANNER';
    }[];
}

export interface PlanningQuote {
    documentId: string;
    revision: string;
    quickRef: string;
    status: QuoteStatus;
    salesDocumentNumber: string;
    salesAreaId: string;
    salesAreaName: string;
    planningId: string;
    planningType: PlanningType;
}

export interface PlanningProject {
    title: string;
    externalApp: string;
    salesforceId: string;
    projectIdSF: string;
    quotes: PlanningQuote[];
}

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    constructor(private http: HttpClient) {}

    public getPlanningProject(salesforceId: string): Observable<PlanningProject> {
        return this.http
            .get<SalesforceQuoteResponse>(`${environment.http.baseUrl}quote/planning-project/${salesforceId}`)
            .pipe(
                map((resp) => {
                    const first = resp.items[0];
                    const quotes: PlanningQuote[] = resp.items
                        .map((quote) => ({
                            documentId: quote.documentId,
                            revision: quote.revision,
                            quickRef: quote.quickRef,
                            status: quote.status,
                            salesDocumentNumber: quote.salesDocumentNumber,
                            salesAreaId: quote.salesAreaId,
                            salesAreaName: quote.salesAreaName,
                            planningId: quote.planningId,
                            planningType: <PlanningType>quote.planningType,
                        }))
                        .filter((item) => Object.values(PlanningType).includes(item.planningType))
                        .sort((a, b) => a.planningType.localeCompare(b.planningType));
                    return {
                        title: first.title,
                        externalApp: first.externalApp,
                        salesforceId: first.salesforceId,
                        projectIdSF: first.projectIdSF,
                        quotes,
                    };
                })
            );
    }
}
