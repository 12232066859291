import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from '../environments/environment';
import { FooterComponent } from './app-footer/footer.component';
import { HeaderComponent } from './app-header/header.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ConfigurationModule } from './configuration/configuration.module';
import { ForbiddenModule } from './forbidden/forbidden.module';
import { NextStepsModule } from './next-steps/next-steps.module';
import { OverviewModule } from './overview/overview.module';
import { SummaryModule } from './summary/summary.module';
import { AppHttpInterceptor } from './_shared/services/http-interceptor.service';
import { SharedModule } from './_shared/shared.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { AuthService } from './_shared/services/auth.service';
import { PermissionService } from './_shared/services/permission.service';
import { StatusPageService } from './_shared/services/status-page.service';
import { forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ConfigitApiService } from './_shared/components/questionnaire/configit-quest-adapter/configit-api.service';
import { AppInsightsService } from './_shared/services/app-insights.service';
import { PlanningProjectModule } from './planning-project/planning-project.module';
import { FeatureBannerComponent } from './feature-banner/feature-banner.component';
import { InstanaErrorHandler } from './instana-error-handler';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './_shared/utils/route-reuse.strategy';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './_shared/services/app.service';

registerLocaleData(localeDe, 'de-DE');

function initializeApp(
    appService: AppService,
    auth: AuthService,
    permissions: PermissionService,
    statusPage: StatusPageService,
    api: ConfigitApiService,
    appInsights: AppInsightsService,
    translate: TranslateService
): () => Promise<any> {
    return () => {
        const language = appService.getBrowserOrQueryLanguage();
        appService.onLanguageChanged$.next(language);
        return forkJoin([statusPage.checkForDowntime(), translate.use(language)])
            .pipe(
                concatMap(([down]) => {
                    if (down) {
                        permissions.isUserAuthorized$.next(true);
                        permissions.isUserLoggedIn$.next(true);
                        return of(true);
                    } else {
                        appInsights.init();
                        auth.init();
                        permissions.getLoggedInUserInfo();
                        return api.fetchConfigitSettings();
                    }
                })
            )
            .toPromise();
    };
}

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent, FeatureBannerComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [
                AppService,
                AuthService,
                PermissionService,
                StatusPageService,
                ConfigitApiService,
                AppInsightsService,
                TranslateService,
            ],
            multi: true,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'l',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        OverviewModule,
        ConfigurationModule,
        PlanningProjectModule,
        NextStepsModule,
        ForbiddenModule,
        MaintenanceModule,
        SummaryModule,
        GlobalHeaderModule.forRoot({
            ...environment.globalHeader,
            appId: environment.saml.appId,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorkerEnabled }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
