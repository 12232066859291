<div class="dialog-container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1 mat-dialog-title>{{ 'QUOTE_DISCOUNT_DIALOG.BUTTON' | translate }}</h1>
        <div mat-dialog-content>
            <table mat-table [dataSource]="data.discounts">
                <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef>{{ 'QUOTE_DISCOUNT_DIALOG.MATERIALGROUP' | translate }}</th>
                    <td mat-cell *matCellDef="let discount">{{ discount.materialGroup }}</td>
                </ng-container>

                <ng-container matColumnDef="partnerDiscount">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'QUOTE_DISCOUNT_DIALOG.PARTNER_DISCOUNT' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let discount">{{ discount.discount | number : '1.1-2' }} %</td>
                </ng-container>

                <ng-container matColumnDef="quoteDiscount">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'QUOTE_DISCOUNT_DIALOG.QUOTE_DISCOUNT' | translate }}
                        <span class="subheader" *ngIf="data.showPriceAdvantage">{{
                            'QUOTE_DISCOUNT_DIALOG.HINT.PRICE_ADVANTAGE' | translate
                        }}</span>
                    </th>
                    <td mat-cell *matCellDef="let discount">
                        <mat-form-field width="30px">
                            <input
                                matInput
                                [id]="discount.materialGroup"
                                [formControlName]="discount.materialGroup"
                                type="number"
                            />
                            <span matTextSuffix>%</span>
                            <mat-error *ngIf="firstErrorIs(discount.materialGroup, 'max')"
                                >{{ 'QUOTE_DISCOUNT_DIALOG.ERROR.MAX' | translate }}
                                {{ discount.maxDiscount | number : '1.1-2' }} %</mat-error
                            >
                            <mat-error *ngIf="firstErrorIs(discount.materialGroup, 'min')"
                                >{{ 'QUOTE_DISCOUNT_DIALOG.ERROR.MIN' | translate }} 0 %</mat-error
                            >
                            <mat-error *ngIf="firstErrorIs(discount.materialGroup, 'required')">{{
                                'QUOTE_DISCOUNT_DIALOG.ERROR.REQUIRED' | translate
                            }}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            </table>
        </div>
        <div mat-dialog-actions fxLayoutAlign="end">
            <button mat-raised-button type="button" (click)="close()">{{ 'COMMON.DIALOG.CANCEL' | translate }}</button>
            <button mat-raised-button type="submit" [disabled]="!form.valid" color="primary">
                {{ 'COMMON.DIALOG.CONFIRM' | translate }}
            </button>
        </div>
    </form>
</div>
