import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class ValidateValueStateMatcher implements ErrorStateMatcher {
    constructor(private validator: (arg: any) => boolean) {}

    public isErrorState(control: UntypedFormControl | null): boolean {
        return !this.validator(control.value);
    }
}
