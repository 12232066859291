import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';
import { SharedModule } from '../_shared/shared.module';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';

@NgModule({
    declarations: [MaintenanceComponent, MaintenanceMessageComponent],
    exports: [MaintenanceMessageComponent],
    imports: [CommonModule, SharedModule],
})
export class MaintenanceModule {}
