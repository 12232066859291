import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export class ConfirmationDialogData {
    public title: string;
    public question: string;
    public no: string;
    public yes: string;
}

export function openConfirmationDialog(
    dialog: MatDialog,
    title: string,
    question: string,
    yes: string,
    no: string,
    onAnswer?: (confirmed) => void
) {
    const dialogRef = dialog.open(ConfirmationDialogComponent, {
        autoFocus: false,
        data: {
            title,
            question,
            yes,
            no,
        },
    });
    if (onAnswer) {
        dialogRef.afterClosed().subscribe(onAnswer);
    }
    return dialogRef;
}

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
    ) {}
}
