import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../_shared/guards/auth.guard';
import { NextStepsComponent } from './next-steps.component';
import { maintenanceGuard } from '../_shared/guards/maintenance.guard';

const nextStepsRoutes: Routes = [
    {
        path: 'next-steps',
        component: NextStepsComponent,
        canActivate: [authGuard, maintenanceGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(nextStepsRoutes)],
    exports: [RouterModule],
})
export class NextStepsRouting {}
