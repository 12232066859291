import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { QuestModule } from '@vi/quest';
import { environment } from 'src/environments/environment';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CardComponent } from './components/card/card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CustomerSearchDialogComponent } from './components/customer-search-dialog/customer-search-dialog.component';
import { CustomerSelectionComponent } from './components/customer-selection/customer-selection.component';
import { DocumentsSelectionComponent } from './components/documents-selection/documents-selection.component';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { OverviewTableComponent } from './components/overview-table/overview-table.component';
import { PackagePackerLogoComponent } from './components/package-packer-logo/package-packer-logo.component';
import { PriceSummaryComponent } from './components/price-summary/price-summary.component';
import { ProductGroupComponent } from './components/product-list/product-group/product-group.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductTableComponent } from './components/product-table/product-table.component';
import { ConfigitQuestAdapterModule } from './components/questionnaire/configit-quest-adapter/configit-quest-adapter.module';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { QuoteDiscountDialogComponent } from './components/quote-discount-dialog/quote-discount-dialog.component';
import { SalesorgDropdownComponent } from './components/salesorg-dropdown/salesorg-dropdown.component';
import { SearchAndFilterComponent } from './components/search-and-filter/search-and-filter.component';
// tslint:disable-next-line:max-line-length
import { SendDocumentsViaEmailDialogComponent } from './components/send-documents-via-email-dialog/send-documents-via-email-dialog.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { FeatureDirective } from './directives/feature.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PriceSummaryDialogComponent } from './components/overview-table/price-summary-dialog/price-summary-dialog.component';
import { DocumentActionsComponent } from './components/documents-selection/document-actions/document-actions.component';
import { ValidToIconComponent } from './components/valid-to-icon/valid-to-icon.component';
import { TemplatesTableComponent } from './components/templates-table/templates-table.component';
import { QuoteCreationDialogComponent } from './components/quote-creation-dialog/quote-creation-dialog.component';
import { DropoutDialogComponent } from './components/dropout-dialog/dropout-dialog.component';
import { ImageDialogComponent } from './components/templates-table/image-dialog/image-dialog.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { MatSelectModule } from '@angular/material/select';
import { QuoteDeleteDialogComponent } from './components/quote-delete-dialog/quote-delete-dialog.component';
import { SchemePreviewComponent } from './components/scheme-preview/scheme-preview.component';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DswFooterComponent } from '@vi-succ/design-system-web';

// registering global local to de-DE to display euro symbol in currency pipe
registerLocaleData(localeDe, 'de-De');

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: environment.http.translations,
            suffix: '/download?file_format=i18next',
        },
        {
            prefix: environment.http.dswTranslations,
            suffix: '/download?file_format=simple_json',
        },
    ]);
}

const angularModules = [
    BrowserAnimationsModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    FlexModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatNativeDateModule,
    NgxUploaderModule,
    MatSelectModule,
];

@NgModule({
    declarations: [
        PackagePackerLogoComponent,
        SalesorgDropdownComponent,
        OverviewTableComponent,
        SearchAndFilterComponent,
        PriceSummaryComponent,
        ProductListComponent,
        QuestionnaireComponent,
        ProductTableComponent,
        DocumentsSelectionComponent,
        BreadcrumbsComponent,
        SendDocumentsViaEmailDialogComponent,
        FeedbackDialogComponent,
        ProductGroupComponent,
        CustomerSearchDialogComponent,
        QuoteDiscountDialogComponent,
        DateFormatPipe,
        LoadingOverlayComponent,
        CustomerSelectionComponent,
        FeatureDirective,
        ConfirmationDialogComponent,
        LoaderComponent,
        MainContentComponent,
        CardComponent,
        SnackbarComponent,
        PriceSummaryDialogComponent,
        DocumentActionsComponent,
        ValidToIconComponent,
        TemplatesTableComponent,
        QuoteCreationDialogComponent,
        DropoutDialogComponent,
        ImageDialogComponent,
        QuoteDeleteDialogComponent,
        SchemePreviewComponent,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'l',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        DatePipe,
    ],
    imports: [
        CommonModule,
        ...angularModules,
        QuestModule.forRoot({
            context: environment.quest.context,
            behavior: {
                allowAnswerReset: true,
                answerResetLabel: '-',
                disableOnCheck: true,
                resettableParts: {
                    enabled: true,
                },
            },
            hint: {
                useDialog: Number.MAX_VALUE,
            },
        }),
        RouterModule,
        ConfigitQuestAdapterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
        }),
        MatSortModule,
        DswFooterComponent,
    ],
    exports: [
        QuestModule,
        RouterModule,
        TranslateModule,
        PackagePackerLogoComponent,
        SalesorgDropdownComponent,
        OverviewTableComponent,
        SearchAndFilterComponent,
        PriceSummaryComponent,
        ProductListComponent,
        QuestionnaireComponent,
        ProductTableComponent,
        DocumentsSelectionComponent,
        BreadcrumbsComponent,
        SendDocumentsViaEmailDialogComponent,
        FeedbackDialogComponent,
        CustomerSearchDialogComponent,
        QuoteDiscountDialogComponent,
        ProductGroupComponent,
        DateFormatPipe,
        CustomerSelectionComponent,
        FeatureDirective,
        LoaderComponent,
        MainContentComponent,
        CardComponent,
        SnackbarComponent,
        DocumentActionsComponent,
        ...angularModules,
        TemplatesTableComponent,
        SchemePreviewComponent,
        DswFooterComponent,
    ],
})
export class SharedModule {}
