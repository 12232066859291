import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SelectedCustomerService } from '../_shared/services/selected-customer.service';
import { AppService } from '../_shared/services/app.service';

export class OverviewDataSource<T, P> implements DataSource<T> {
    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    private paginator: MatPaginator;
    private sort: MatSort;

    private params: any;

    public loading$ = this.loadingSubject.asObservable();

    constructor(
        private appService: AppService,
        private selectedCustomerService: SelectedCustomerService,
        private load: (params: P) => Observable<any>
    ) {}

    public hasData() {
        return this.dataSubject.value && this.dataSubject.value.length;
    }

    public resetPaging() {
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
    }

    public init(paginator: MatPaginator, sort: MatSort) {
        this.paginator = paginator;
        this.sort = sort;
    }

    public connect(): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    public disconnect(): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    public loadOverviewData({
        parameters,
        sortBy = 'createdDate',
        sortOrder = 'desc',
        pageSize = 25,
    }: { parameters?: any; sortBy?: string; sortOrder?: string; pageSize?: number } = {}): Observable<any> {
        if (parameters) {
            this.params = parameters;
        }
        this.loadingSubject.next(true);
        const page = this.paginator ? this.paginator.pageIndex : 0;
        const partnerId = this.selectedCustomerService.customerForPartnerMode$.value?.number;

        return this.loadData({
            ...this.params,
            page,
            pageSize: this.paginator ? this.paginator.pageSize : pageSize,
            sortBy: this.sort ? this.sort.active : sortBy,
            sortOrder: this.sort ? this.sort.direction : sortOrder,
            partnerId,
            salesArea: this.appService.getSalesAreaName(),
        }).pipe(
            tap((result) => {
                this.paginator.length = result.totalCount;
                this.dataSubject.next(result ? result.items : []);
            }),
            finalize(() => this.loadingSubject.next(false))
        );
    }

    private loadData(params: P) {
        return this.load(params);
    }
}
