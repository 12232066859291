<app-main-content content>
    <div main fxLayout="row" fxLayoutGap="10px">
        <a mat-icon-button [routerLink]="planning ? ['../', 'planning-project', salesforceId] : ['/overview/quotes']">
            <mat-icon>arrow_back</mat-icon>
        </a>
        <app-breadcrumbs
            formerPage="{{
                (planning ? 'COMMON.BREADCRUMBS.PLANNING_PROJECT' : 'COMMON.BREADCRUMBS.OVERVIEW') | translate
            }}"
            currentPage="{{ 'COMMON.BREADCRUMBS.CONFIGURATION' | translate }}"
            [navigateTo]="planning ? ['../', 'planning-project', salesforceId] : '/overview'"
        ></app-breadcrumbs>
    </div>
    <div class="top-container" main fxLayout="column">
        <app-customer-selection
            class="customer-selection-container"
            *ngIf="permissionService.isUserAnEmployee"
        ></app-customer-selection>
        <h2 class="header-title">{{ 'CONFIGURATION.TITLE' | translate }}</h2>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start bottom" class="offer-title-container">
            <span data-at-quickref class="quickref">{{ quickRef }}</span>
            <mat-form-field fxFlex class="offer-title-field" (click)="onOfferTitleClick()">
                <mat-label>{{ 'CONFIGURATION.OFFER_TITLE_PLACEHOLDER' | translate }}</mat-label>
                <input
                    data-at-quote-title
                    #offerTitleInput
                    matInput
                    maxlength="100"
                    [(ngModel)]="offerTitle"
                    [errorStateMatcher]="errorOnEmptyMatcher"
                    (keyup.enter)="onOfferTitleBlur()"
                    (blur)="onOfferTitleBlur()"
                />
                <mat-icon matPrefix>edit</mat-icon>
                <mat-hint *ngIf="!isOfferTitleDisabled" align="end">{{ offerTitle.length }} / 100</mat-hint>
                <mat-error> {{ 'CONFIGURATION.VALIDATION.PLEASE_PROVIDE_TITLE_MESSAGE' | translate }}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div main #contentContainer class="content-container" [class.hidden]="!isQuestFormReady">
        <app-card
            class="quest-lib-container"
            [class.quest-lib-container-min-height]="isQuestFormReady"
            [isFooter]="false"
            [isHeader]="false"
        >
            <div body>
                <app-questionnaire
                    #questionaire
                    [quickRef]="quickRef"
                    [documentId]="documentId"
                    [latestRevision]="latestRevision"
                    [title]="offerTitle"
                    [salesforceId]="salesforceId"
                    [planning]="planning"
                    [quotationRequestId]="quotationRequestId"
                    (isReady)="onQuestFormReady()"
                    (isComplete)="onQuestFormComplete($event)"
                    [lineType]="lineType"
                ></app-questionnaire>
            </div>
        </app-card>
        <div class="outer-container" fxLayout="column" *ngIf="isQuestFormReady">
            <div class="sticker" fxLayout="column">
                <app-price-summary></app-price-summary>
                <app-product-list></app-product-list>
                <button
                    class="next"
                    mat-raised-button
                    type="button"
                    color="primary"
                    [disabled]="!isQuestFormComplete"
                    (click)="submit()"
                >
                    {{ 'CONFIGURATION.SUMMARY' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div main *ngIf="!isQuestFormReady">
        <app-loader [message]="loaderTextKey | translate"></app-loader>
    </div>
</app-main-content>
