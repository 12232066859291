import { ErrorHandler, Injectable } from '@angular/core';
import { InstanaService } from './_shared/services/instana.service';
import { environment } from '../environments/environment';

@Injectable()
export class InstanaErrorHandler implements ErrorHandler {
    constructor(private instana: InstanaService) {}

    public handleError(error) {
        if (environment.instana) {
            this.instana.reportError(error);
        } else {
            throw error;
        }
    }
}
