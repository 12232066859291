import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '../../services/http.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UrlService } from '../../services/url.service';
import { AppService } from '../../services/app.service';
import { QuoteService } from '../../services/quote.service';

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
    public emailValue: string;
    public subjectValue: string;
    public messageValue: string;
    public isLoading = false;

    constructor(
        private urlService: UrlService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private http: HttpService,
        private appService: AppService,
        public dialogRef: MatDialogRef<FeedbackDialogComponent>,
        private quoteService: QuoteService
    ) {}

    public sendFeedback(): void {
        const payload = {
            sender: this.emailValue,
            subject:
                this.quoteService.getCurrentQuote()?.quote?.quickRef &&
                this.quoteService.getCurrentQuote()?.selectedCustomer
                    ? this.translateService.instant('FEEDBACK_DIALOG.PRE_FILLED_SUBJECT', {
                          subject: this.subjectValue,
                          qRef: this.quoteService.getCurrentQuote()?.quote?.quickRef,
                          customer: this.quoteService.getCurrentQuote()?.selectedCustomer,
                      })
                    : this.subjectValue,
            message: this.messageValue,
            salesOrg: this.appService.getSalesOrg(),
        };
        const url = this.urlService.getUrl('sendFeedback');
        this.isLoading = true;
        this.http.post(url, payload).subscribe(
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('FEEDBACK_DIALOG.SENDING_FEEDBACK.SUCCESS_MSG'),
                    isFailure: false,
                });
                this.dialogRef.close();
                this.isLoading = false;
            },
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('FEEDBACK_DIALOG.SENDING_FEEDBACK.ERROR_MSG'),
                    isFailure: true,
                });
                this.isLoading = false;
            }
        );
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
