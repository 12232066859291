import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../_shared/services/http.service';
import { UrlService } from '../_shared/services/url.service';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { AppService } from '../_shared/services/app.service';

export interface SearchParams {
    salesArea: string;
    partnerId?: string;
    page?: string;
    pageSize: string;
    sortBy: string;
    sortOrder: string;
    search?: string;
    fromDate?: Date;
    toDate?: Date;
}

export interface UseTemplateParams {
    documentId: string;
    revision: number;
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class OverviewDataService {
    constructor(private urlService: UrlService, private httpService: HttpService, private appService: AppService) {}

    public copyOffer(params: any): Observable<any> {
        const url = this.urlService.getUrl('quote.copy', params);
        return this.httpService.post(url, params);
    }

    public getQuote(
        documentId: string
    ): Observable<{ title: string; customProperties: { key: string; stringValue: string }[] }> {
        const url = `${environment.http.baseUrl}quote/${documentId}/revision/0`;
        return this.httpService.get(url);
    }

    public createNewOffer(params: any): Observable<any> {
        const url = this.urlService.getUrl('quote.create');
        return this.httpService.post(url, params);
    }

    public deleteOffer(params: any): Observable<any> {
        const url = this.urlService.getUrl('quote.delete', params);
        return this.httpService.delete(url);
    }

    public getAvailableCancellationReasons() {
        const url = this.urlService.getUrl('quote.cancelKeys');
        return this.httpService.get(url);
    }

    public cancelOffer(documentId: string, latestRevision: number, cancelKey: string): Observable<any> {
        const url = this.urlService.getUrl('quote.cancel', { documentId, latestRevision });
        return this.httpService.post(url, {
            language: this.appService.getLanguageOnly(),
            country: this.appService.getLocation(),
            cancelKey,
        });
    }

    public useTemplate({ documentId, revision, title }: UseTemplateParams) {
        const url = `${environment.http.baseUrl}quote/templates/${documentId}/revision/${revision}/to-quote`;
        return this.httpService.post(url, { title });
    }

    public searchOffers(parameters: SearchParams) {
        const url = `${environment.http.baseUrl}quote`;
        const params: HttpParams = new HttpParams({
            fromObject: this.removeUndefined({
                page: parameters.page?.toString(),
                perPage: parameters.pageSize,
                sortBy: parameters.sortBy,
                sortOrder: parameters.sortOrder,
                salesArea: parameters.salesArea?.trim(),
                partnerId: parameters.partnerId?.trim(),
                createdAfter: parameters.fromDate?.toISOString(),
                createdBefore: parameters.toDate?.toISOString(),
                title: parameters.search?.trim(),
            }),
        });
        return this.httpService.get(url, params);
    }

    public searchTemplates(parameters: SearchParams) {
        const url = `${environment.http.baseUrl}quote/templates`;
        const params: HttpParams = new HttpParams({
            fromObject: this.removeUndefined({
                page: parameters.page?.toString(),
                perPage: parameters.pageSize,
                sortBy: parameters.sortBy,
                sortOrder: parameters.sortOrder,
                salesArea: parameters.salesArea?.trim(),
                createdAfter: parameters.fromDate?.toISOString(),
                createdBefore: parameters.toDate?.toISOString(),
                keyword: parameters.search?.trim(),
            }),
        });
        return this.httpService.get(url, params);
    }

    private removeUndefined(obj: { [key: string]: string }) {
        Object.keys(obj).forEach((key) => (obj[key] ? {} : delete obj[key]));
        return obj;
    }
}
