import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanningProjectRoutingModule } from './planning-project-routing.module';
import { PlanningProjectComponent } from './planning-project.component';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
    declarations: [PlanningProjectComponent],
    imports: [CommonModule, PlanningProjectRoutingModule, SharedModule],
})
export class PlanningProjectModule {}
