<div class="summary-row" fxLayout="row" fxLayoutAlign="space-between baseline">
    <div>{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
    <div>{{ data.totalGross | currency : data.currency : 'symbol-narrow' }}</div>
</div>
<div class="summary-row" fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="data.totalDiscount">
    <div>{{ 'CONFIGURATION.PANEL.LABEL.PACKAGE_PRICE_ADVANTAGE' | translate }}:</div>
    <div class="discount">- {{ data.totalDiscount | currency : data.currency : 'symbol-narrow' }}</div>
</div>

<div
    class="summary-row"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    *ngIf="data.totalDiscount && data.totalPrice"
>
    <div>{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_PRICE' | translate }}:</div>
    <div>{{ data.totalPrice | currency : data.currency : 'symbol-narrow' }}</div>
</div>

<div class="summary-row total" fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="data.netPrice">
    <div>{{ 'CONFIGURATION.PANEL.LABEL.NET_PRICE' | translate }}:</div>
    <div>{{ data.netPrice | currency : data.currency : 'symbol-narrow' }}</div>
</div>

<div
    class="summary-row"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    *ngIf="data.voucherCode && data.voucherValue"
>
    <div>
        {{
            'OVERVIEW.TABLE.PRICE.OVERLAY.LABEL.VOUCHER' | translate : { voucherCode: data.voucherCode.toLowerCase() }
        }}:
    </div>
    <div class="discount">- {{ data.voucherValue | currency : data.currency : 'symbol-narrow' }}</div>
</div>

<div class="info-container" *ngIf="data.noPriceAdvantage">
    <mat-icon class="info-icon">info</mat-icon>
    <span>
        {{ 'CONFIGURATION.PANEL.PRICE_TOOLTIP_NO_PRICE_ADVANTAGE' | translate }}
    </span>
</div>

<div class="info-container" *ngIf="data.voucherCode && (data.voucherValue || data.extraBonus)">
    <mat-icon class="info-icon">info</mat-icon>
    <span>
        {{
            'PRICE_SUMMARY.INFO_TEXT.VOUCHER.' + voucherTypeKey
                | translate : { voucherCode: data.voucherCode.toLowerCase() }
        }}
    </span>
    <span *ngIf="data.voucherValue">
        {{
            'PRICE_SUMMARY.INFO_TEXT.VOUCHER.VOUCHER_VALUE.' + voucherTypeKey
                | translate : { voucherValue: data.voucherValue | currency : data.currency : 'symbol-narrow' }
        }}
    </span>
    <span *ngIf="data.extraBonus">
        {{
            'PRICE_SUMMARY.INFO_TEXT.VOUCHER.EXTRA_BONUS'
                | translate : { extraBonus: data.extraBonus | currency : data.currency : 'symbol-narrow' }
        }}
    </span>
</div>

<div class="info-container" *ngIf="data.totalDiscount && data.totalPrice">
    <mat-icon class="info-icon">info</mat-icon>
    <span>
        {{ 'PRICE_SUMMARY.INFO_TEXT.PRICE_ADVANTAGE' | translate }}
    </span>
</div>

<div class="info-container" *ngIf="partnerShopTranslationAvailable">
    <mat-icon class="info-icon">info</mat-icon>
    <span>
        {{ 'PRICE_SUMMARY.INFO_TEXT.PARTNER_SHOP' | translate }}
    </span>
</div>
