import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Quest } from '@vi/quest';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/_shared/services/app.service';
import { environment } from './../../../../../environments/environment';
import { ConfigitApiService } from './configit-api.service';
import { ConfigitQuestAdapterService } from './configit-quest-adapter.service';
import { ConfigitConfigurationAndProducts, ConfigitModelOriginal, ConfigitTemplate } from './configit-types.interface';
import { PackerConfigitQuestModelTransform } from './packer-configit-quest-model-transform';

@Injectable()
export class PackerConfigitQuestAdapterService extends ConfigitQuestAdapterService {
    constructor(
        protected api: ConfigitApiService,
        protected dialog: MatDialog,
        protected translate: TranslateService,
        protected appService: AppService
    ) {
        super(api, translate, dialog);

        this.transformer = new PackerConfigitQuestModelTransform(environment.quest, translate);
    }

    public getWithConfiguration(
        name: string,
        configuration: Observable<ConfigitConfigurationAndProducts>
    ): Observable<Quest> {
        const params = {
            material: name,
        };
        return forkJoin([this.api.getTemplate(params), configuration]).pipe(
            map(([template, conf]: [ConfigitTemplate, ConfigitConfigurationAndProducts]) => {
                return {
                    template,
                    configuration: conf.configuration,
                    bomItems: conf.bomItems,
                };
            }),
            map((model: ConfigitModelOriginal) => {
                return this.addAssignments(model, model.configuration.newAssignments) && model;
            }),
            map((model: ConfigitModelOriginal) => this.transformer.transform(model))
        );
    }

    public getConfiguration(material: string, assignments?: any) {
        const params = {
            material,
            plant: '0500',
            usage: '5',
        };

        params.plant = this.appService.getSalesOrg();
        return this.api.getConfiguration(params, assignments);
    }
}
