<div>
    <div *ngIf="selectedCustomerName === undefined">
        <button mat-raised-button color="primary" (click)="openCustomerSearchDialog()">
            {{ buttonText | translate }}
        </button>
    </div>
    <div class="selected-customer-wrapper" *ngIf="selectedCustomerName !== undefined">
        <div class="label">{{ 'CONFIGURATION.CUSTOMER_SEARCH.SELECTED_CUSTOMER' | translate }}:</div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="customer-name">{{ selectedCustomerName }}</span>
            <button
                *ngIf="showClearButton"
                matTooltip="{{ 'CONFIGURATION.CUSTOMER_SEARCH.CLEAR_SELECTION.TOOLTIP' | translate }}"
                class="clear-icon"
                mat-icon-button
                aria-label="Clear"
                (click)="clearSelectedCustomer()"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
</div>
