import { Component, OnInit } from '@angular/core';
import { PlanningProject, PlanningQuote, PlanningType, ProjectsService } from '../_shared/services/projects.service';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../_shared/services/app.service';
import { environment } from '../../environments/environment';
import { SnackBarService } from '../_shared/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-planning-project',
    templateUrl: './planning-project.component.html',
    styleUrls: ['./planning-project.component.scss'],
})
export class PlanningProjectComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private projectsService: ProjectsService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        private appService: AppService
    ) {}

    public loading: boolean;
    public project: PlanningProject;
    public viGuidePlanningUrl: string;

    ngOnInit(): void {
        const { salesforceId } = this.route.snapshot.params;
        const { externalApp } = this.route.snapshot.queryParams;
        this.setAppTitle(externalApp);
        if (salesforceId) {
            this.loading = true;
            this.projectsService
                .getPlanningProject(salesforceId)
                .pipe(finalize(() => (this.loading = false)))
                .subscribe(
                    (response) => {
                        this.setAppTitle(response.externalApp);
                        this.viGuidePlanningUrl = this.getViguidePlanningUrl(response);
                        this.project = response;
                    },
                    (error) => {
                        if (error.status === 404) {
                            this.snackBarService.openSnackBar({
                                message: this.translateService.instant(
                                    'PLANNING.SNACK_BAR.FETCH_PROJECTS.ERROR.NOT_FOUND'
                                ),
                                isFailure: true,
                            });
                        } else {
                            this.snackBarService.openSnackBar({
                                message: this.translateService.instant(
                                    'PLANNING.SNACK_BAR.FETCH_PROJECTS.ERROR.UNKNOWN'
                                ),
                                isFailure: true,
                            });
                        }
                    }
                );
        }
    }

    public getImageByType(quote: PlanningQuote) {
        switch (quote.planningType) {
            case PlanningType.heatpump:
                return '../../assets/images/heatpump.svg';
            case PlanningType.photovoltaic:
                return '../../assets/images/photovoltaic.svg';
        }
    }

    public supportsConfiguration(quote: PlanningQuote) {
        // currently only heat pump supports configuration
        return quote.planningType === PlanningType.heatpump;
    }

    public isClosed(quote: PlanningQuote) {
        return quote.status === 'Quoted';
    }

    public queryParams(quote: PlanningQuote) {
        return {
            documentId: quote.documentId,
            latestRevision: quote.revision,
            planning: true,
            planningType: quote.planningType,
            salesforceId: this.project.salesforceId,
            ...(this.isClosed(quote) && { sapDocumentId: quote.salesDocumentNumber }),
            ...(!this.isClosed(quote) && { title: this.project.title, quickRef: quote.quickRef }),
        };
    }

    private setAppTitle(title: string) {
        if (title) {
            this.appService.appTitle$.next(title);
        }
    }

    private getViguidePlanningUrl(project: PlanningProject) {
        const quoteAdded = project.quotes.some((q) => q.status === 'Quoted');
        const url = new URL(project.salesforceId, environment.http.viGuidePlanningUrl);
        url.searchParams.append('quote_added', quoteAdded.toString());
        return url.toString();
    }
}
