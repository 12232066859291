<div class="feedback-dialog-container">
    <h2 class="dialog-title">{{ 'FEEDBACK_DIALOG.TITLE' | translate }}</h2>
    <div class="description">
        {{ 'FEEDBACK_DIALOG.DESCRIPTION' | translate }}
    </div>
    <form #feedbackForm="ngForm">
        <div fxLayout="column" fxLayoutAlign="start bottom">
            <mat-form-field fxFlex class="feedback-email">
                <input
                    data-at-feedback-email
                    matInput
                    required
                    type="email"
                    name="email"
                    [email]="true"
                    [placeholder]="'FEEDBACK_DIALOG.SENDER_EMAIL_LABEL' | translate"
                    [(ngModel)]="emailValue"
                />
                <mat-icon matPrefix class="email-icon">email</mat-icon>
                <mat-error>
                    {{ 'FEEDBACK_DIALOG.EMAIL_VALIDATION_ERROR_MSG' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex class="feedback-subject">
                <input
                    data-at-feedback-subject
                    matInput
                    required
                    name="subject"
                    [(ngModel)]="subjectValue"
                    [placeholder]="'FEEDBACK_DIALOG.SUBJECT_LABEL' | translate"
                />
                <mat-error>
                    {{ 'FEEDBACK_DIALOG.SUBJECT_REQUIRED_ERROR_MSG' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex class="feedback-message">
                <textarea
                    data-at-feedback-message
                    matInput
                    required
                    name="message"
                    [placeholder]="'FEEDBACK_DIALOG.MESSAGE_LABEL' | translate"
                    [(ngModel)]="messageValue"
                    rows="4"
                ></textarea>
                <mat-error>
                    {{ 'FEEDBACK_DIALOG.MESSAGE_REQUIRED_ERROR_MSG' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="footer" mat-dialog-actions fxLayout="row">
        <div *ngIf="!isLoading" fxFlex fxLayout="row" fxLayoutAlign="end start">
            <button mat-button color="primary" (click)="closeDialog()">
                {{ 'COMMON.DIALOG.CANCEL' | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="sendFeedback()"
                [disabled]="feedbackForm.invalid || isLoading"
            >
                <mat-icon>send</mat-icon>
                {{ 'COMMON.DIALOG.SEND' | translate }}
            </button>
        </div>
        <div fxFlex *ngIf="isLoading" fxLayoutAlign="center center">
            <app-loader [message]="'FEEDBACK_DIALOG.LOADER.SENDING_FEEDBACK' | translate"></app-loader>
        </div>
    </div>
</div>
