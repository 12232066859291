import { Component, ElementRef, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

export interface SnackBarData {
    message: string;
    isFailure?: boolean;
}

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
    public dataSnackBar: SnackBarData;

    constructor(public snackBarRef: MatSnackBarRef<SnackbarComponent>, public el: ElementRef) {}

    public ngOnInit() {
        this.el.nativeElement.parentNode.parentNode.classList.add('snack-bar-overlay-pane');
    }

    public dismiss(): void {
        this.snackBarRef.dismiss();
    }
}
