import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerSearchService {
    constructor(private httpService: HttpService, private appService: AppService) {}

    public findCustomers(keyword: string): Observable<FindCustomerRecord[]> {
        const url = `${environment.http.quotation}customer`;
        const params = new HttpParams({
            fromObject: {
                salesOrg: this.appService.getSalesOrg(),
                keyword,
            },
        });
        return this.httpService.get(url, params);
    }
}

interface FindCustomerRecord {
    name: string;
    number: string;
    calculationSchema: string;
}
