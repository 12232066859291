import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface QuoteDiscount {
    materialGroup: string;
    partnerDiscount: string; // this is in fact the quoteDiscount NOT the partner discount
}

@Injectable({
    providedIn: 'root',
})
export class QuoteDiscountsService {
    public quoteDiscounts$: BehaviorSubject<QuoteDiscount[]> = new BehaviorSubject<QuoteDiscount[]>([]);

    public setQuoteDiscounts(discounts: QuoteDiscount[]) {
        this.quoteDiscounts$.next(discounts);
    }

    public clearQuoteDiscounts() {
        this.quoteDiscounts$.next([]);
    }

    public quoteDiscounts() {
        return this.quoteDiscounts$.asObservable();
    }

    public getQuoteDiscounts() {
        return this.quoteDiscounts$.getValue().filter((it) => !!Number(it.partnerDiscount));
    }

    public getQuoteDiscount(group: string): QuoteDiscount {
        const result = this.getQuoteDiscounts().find((it) => it.materialGroup === group);
        if (result) {
            return result;
        } else {
            return {
                materialGroup: group,
                partnerDiscount: '0',
            };
        }
    }
}
