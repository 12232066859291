import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

interface UrlMapper {
    offline: string;
    http: string;
}

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(private appService: AppService) {}

    private endpoints: { [key: string]: (args?: any) => UrlMapper } = {
        'quote.create': () => {
            return {
                offline: '',
                http: 'quote',
            };
        },
        'quote.copy': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/copy`,
            };
        },
        'quote.delete': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}`,
            };
        },
        'quote.cancel': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/cancel`,
            };
        },
        'quote.cancelKeys': () => {
            return {
                offline: '',
                http: `quote/cancel-keys?language=${this.appService.getLanguageOnly()}`,
            };
        },
        'quote.close': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/close`,
            };
        },
        'product.name': (args: any) => {
            return {
                offline: 'product-name.json',
                http: `material/${args.material}?salesAreaName=${args.salesAreaName}`,
            };
        },
        'pdf.offer.print': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/pdf/generate`,
            };
        },
        'pdf.offer.taskState': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/getTaskState`,
            };
        },
        'pdf.offer.download': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/pdf/download`,
            };
        },
        'pdf.energyLabel.download': (args: any) => {
            return {
                offline: '',
                http: `energy/${this.appService.getSalesOrg()}/${this.appService.getLanguageOnly()}/pdf/${args.hash}`,
            };
        },
        'employee.languages': () => {
            return {
                offline: '',
                http: `employee`,
            };
        },
        'customer.languages': () => {
            return {
                offline: '',
                http: `customer/data`,
            };
        },
        'next-steps.attachments': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments`,
            };
        },
        'next-steps.attachment.delete': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments/${args.id}`,
            };
        },
        'next-steps.attachment.download': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments/${args.documentUri}`,
            };
        },
        sendMailWithDocuments: (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/mail`,
            };
        },
        sendFeedback: () => {
            return {
                offline: '',
                http: `feedback`,
            };
        },
        customerDiscounts: (args: any) => {
            return {
                offline: '',
                http: `customer/${args.customer}/discounts?salesOrg=${this.appService.getSalesOrg()}`,
            };
        },
        validateVoucherCode: () => {
            return {
                offline: '',
                http: `voucher`,
            };
        },
    };

    public getUrl(name: string, args?: object): string {
        // by having offline=1 in url we can enable offline mode
        if (environment.offline.enabled || window.location.href.indexOf(environment.offline.keyword) !== -1) {
            return environment.offline.baseUrl + this.endpoints[name](args).offline;
        }

        return environment.http.baseUrl + this.endpoints[name](args).http;
    }
}
