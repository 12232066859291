import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { ConfigurationComponent } from './configuration.component';
import { ConfigurationRouting } from './configuration.routing';

@NgModule({
    declarations: [ConfigurationComponent],
    imports: [CommonModule, ConfigurationRouting, SharedModule],
})
export class ConfigurationModule {}
