export enum ConfigitValueState {
    // Creates conflict on selection. The value can’t be assigned without removing assignments to other variables.
    Forceable = 0,
    // The value can never be assigned. (usually will not be displayed in the frontend)
    Blocked = 1,
    // The value is assigned by the system
    Assignable = 2,
    // The value is assigned by the user
    UserAssigned = 3,
    // The value was selected by system
    SystemAssigned = 4,
    // The value has been removed
    Removed = 5,
}
