import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../services/permission.service';
import { MatPaginator } from '@angular/material/paginator';
import { OverviewDataSource } from '../../../overview/overview-data-source';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { OverlayDialogHelper } from '../../utils/overlay-dialog.helper';
import * as moment from 'moment';
import { ConfigitQuote } from '../../services/quote.service';
import {
    PriceSummaryDialogComponent,
    PriceSummaryDialogData,
} from './price-summary-dialog/price-summary-dialog.component';
import { DOCUMENT } from '@angular/common';
import { openQuoteCreationDialog } from '../quote-creation-dialog/quote-creation-dialog.component';
import { OverviewDataService } from '../../../overview/overview.data.service';
import { openQuoteDeleteDialog } from '../quote-delete-dialog/quote-delete-dialog.component';
import { ImageDialogComponent, ImageDialogData } from '../templates-table/image-dialog/image-dialog.component';
import { AppService } from '../../services/app.service';

const CANCEL_DEFAULT_REASON = '19';

@Component({
    selector: 'app-overview-table',
    templateUrl: './overview-table.component.html',
    styleUrls: ['./overview-table.component.scss'],
})
export class OverviewTableComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    private helper: OverlayDialogHelper<PriceSummaryDialogComponent, PriceSummaryDialogData>;
    private viewer: OverlayDialogHelper<ImageDialogComponent, ImageDialogData>;
    private today: moment.Moment;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @Input()
    public dataSource: OverviewDataSource<any, any>;
    @Input()
    public isEmployee: boolean;

    @Output()
    public viewOffer = new EventEmitter<any>();
    @Output()
    public copyOffer = new EventEmitter<any>();
    @Output()
    public deleteOffer = new EventEmitter<any>();
    @Output()
    public pagingError = new EventEmitter<any>();
    @Output()
    public sortError = new EventEmitter<any>();

    public columnsToDisplayForCustomer = [
        'documentId',
        'createdDate',
        'schemePreview',
        'title',
        'totalGross',
        'creatorFullName',
        'status',
        'actions',
    ];
    public columnsToDisplayForEmployee = [
        'documentId',
        'partnerNumber',
        'createdDate',
        'schemePreview',
        'title',
        'totalGross',
        'creator',
        'status',
        'actions',
    ];
    public columnsToDisplay;

    constructor(
        @Inject(DOCUMENT) document: Document,
        private translationService: TranslateService,
        private permissionService: PermissionService,
        private selectedCustomerService: SelectedCustomerService,
        private dialog: MatDialog,
        private dataService: OverviewDataService,
        private appService: AppService
    ) {
        this.helper = new OverlayDialogHelper<PriceSummaryDialogComponent, PriceSummaryDialogData>(
            document,
            this.dialog,
            PriceSummaryDialogComponent,
            'price-summary-dialog'
        );
        this.viewer = new OverlayDialogHelper<ImageDialogComponent, ImageDialogData>(
            document,
            dialog,
            ImageDialogComponent,
            'image-dialog'
        );
    }

    public ngOnInit() {
        this.today = moment.utc().startOf('day');
        this.setColumnsToDisplay();
        this.dataSource.init(this.paginator, this.sort);
        this.translatePaginator();
        this.translationService.onLangChange.subscribe(() => this.translatePaginator());
        this.selectedCustomerService.customerForPartnerMode$.subscribe(() => this.setColumnsToDisplay());
        this.appService.salesOrg$.subscribe(() => this.setColumnsToDisplay());
    }

    private setColumnsToDisplay() {
        const noPriceAdvantage =
            this.permissionService.isUserAnEmployee && this.appService.isNoPriceAdvantageCheckboxVisible();

        this.columnsToDisplay = [
            'validStatus',
            ...(noPriceAdvantage ? ['noPriceAdvantage'] : []),
            ...(this.permissionService.isUserAnEmployee && !this.isPartnerMode
                ? this.columnsToDisplayForEmployee
                : this.columnsToDisplayForCustomer),
        ];
    }

    public get isPartnerMode() {
        return !!this.selectedCustomerService.customerForPartnerMode$?.value;
    }

    private translatePaginator() {
        const intl = this.paginator._intl;
        intl.itemsPerPageLabel = this.translationService.instant('OVERVIEW.TABLE.PAGINATOR.LABEL.ITEMS_PER_PAGE');
        intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            const start = page * pageSize;
            const end = start < length ? Math.min(start + pageSize, length) : start + pageSize;
            return this.translationService.instant('OVERVIEW.TABLE.PAGINATOR.LABEL.RANGE', {
                page: start + 1,
                pageSize: end,
                length,
            });
        };
        intl.changes.next();
    }

    public ngAfterViewInit(): void {
        // listen to paging events
        this.paginator.page
            .pipe(
                mergeMap(() => this.dataSource.loadOverviewData()),
                catchError(() => {
                    this.pagingError.emit();
                    return EMPTY;
                })
            )
            .subscribe();

        // listen to sort events
        this.sort.sortChange
            .pipe(
                tap(() => this.dataSource.resetPaging()), // reset to first page when sorting
                mergeMap(() => this.dataSource.loadOverviewData()),
                catchError(() => {
                    this.sortError.emit();
                    return EMPTY;
                })
            )
            .subscribe();
    }

    public onViewClick(quote) {
        this.viewOffer.emit(quote);
    }

    public onCopyClick(quote) {
        openQuoteCreationDialog(this.dialog, {
            title: this.translationService.instant('OVERVIEW.DIALOG.COPY_OFFER.TITLE'),
            confirm: this.translationService.instant('COMMON.DIALOG.CONFIRM'),
            cancel: this.translationService.instant('COMMON.DIALOG.CANCEL'),
            quoteTitle: this.translationService.instant('OVERVIEW.DIALOG.COPY_OFFER.COPY') + ` ${quote.title}`,
        }).subscribe((title) => {
            if (title) {
                this.copyOffer.emit({
                    ...quote,
                    title,
                });
            }
        });
    }

    public onDeleteClick(quote) {
        let reasons$: Subject<{ [key: string]: string }>;
        if (this.isEmployee && quote.status.toLowerCase() !== 'new') {
            reasons$ = new Subject<{ [key: string]: string }>();
            this.dataService.getAvailableCancellationReasons().pipe(takeUntil(this.unsubscribe$)).subscribe(reasons$);
        }

        openQuoteDeleteDialog(
            this.dialog,
            this.translationService.instant('OVERVIEW.DIALOG.DELETE_OFFER.TITLE'),
            `${this.translationService.instant('OVERVIEW.DIALOG.DELETE_OFFER.MESSAGE')} ${quote.title}`,
            this.translationService.instant('COMMON.DIALOG.CONFIRM'),
            this.translationService.instant('COMMON.DIALOG.CANCEL'),
            reasons$
        ).subscribe((reason) => {
            reason = reason === true ? CANCEL_DEFAULT_REASON : reason;
            if (reason) {
                this.deleteOffer.emit({
                    ...quote,
                    reason,
                });
            }
        });
    }

    public getCreatorFullName(obj) {
        return obj.authorName ? `${obj.authorName.firstName || ''} ${obj.authorName.familyName || ''}` : '';
    }

    public handlePriceSummaryEvent(event: MouseEvent, obj) {
        if (obj.totalGross || obj.totalDiscount || obj.totalPrice) {
            this.helper.handleEvent(event, obj);
        }
    }

    public getStatus(item: ConfigitQuote) {
        return moment.utc(item.validTo).startOf('day').add(1, 'day').isSameOrBefore(this.today) &&
            item.status === 'Quoted'
            ? 'EXPIRED'
            : item.status.toUpperCase();
    }

    public onImageHover(event: MouseEvent, obj) {
        if (obj.status.toLowerCase() !== 'new') {
            this.viewer.handleEvent(event, {
                quoteId: obj.documentId,
                imageUrl: obj.schemePreview,
            });
        }
    }

    public ngOnDestroy() {
        this.helper.destroy();
    }
}
