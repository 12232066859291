import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Quote } from './quote.service';
import { Router } from '@angular/router';
import { SnackBarService } from './snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class QuotePermissionService {
    constructor(
        private appService: AppService,
        private router: Router,
        private snackbar: SnackBarService,
        private translate: TranslateService
    ) {}

    public check(quote: Quote) {
        const salesOrg = quote.quote.salesArea.salesOrganization;
        if (this.appService.availableSalesOrgs$.value.includes(salesOrg)) {
            const persistent = !quote.externalApp;
            this.appService.setSalesOrg(salesOrg, persistent);
            // switch the language, but only if the current language does not match the salesOrg
            const currentLanguage = this.appService.getLanguage();
            const localesMatchingSalesOrg = environment.app.supportedLanguages.filter((l) => l.salesOrg === salesOrg);
            if (!localesMatchingSalesOrg.some((l) => l.locale === currentLanguage)) {
                const language = environment.app.supportedLanguages.find((l) => l.salesOrg === salesOrg)?.locale;
                this.appService.setLanguage(language, persistent);
            }
        } else {
            // tslint:disable-next-line:no-console
            console.log(
                `quote salesOrg: ${salesOrg}, available for user: ${this.appService.availableSalesOrgs$.value.toString()}`
            );
            this.router.navigate(['overview']).then(() => {
                this.snackbar.openSnackBar(
                    {
                        message: this.translate.instant('GENERAL.SNACK_BAR.QUOTE_PERMISSION.ERROR'),
                        isFailure: true,
                    },
                    5000
                );
            });
        }
    }
}
