import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SnackbarComponent, SnackBarData } from '../components/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    private snackBarRef: MatSnackBarRef<SnackbarComponent>;

    constructor(public snackBar: MatSnackBar) {}

    public openSnackBar(data: SnackBarData, duration: number = 3000): Observable<any> {
        this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
            duration,
            panelClass: 'snack-bar-panel',
            data,
        });
        this.snackBarRef.instance.dataSnackBar = data;

        return this.snackBarRef.afterDismissed();
    }

    public closeSnackBar(): void {
        this.snackBarRef.dismiss();
    }
}
