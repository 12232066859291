import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-package-packer-logo',
    templateUrl: './package-packer-logo.component.html',
    styleUrls: ['./package-packer-logo.component.scss'],
})
export class PackagePackerLogoComponent {
    constructor() {}

    @Input()
    public externalApp: string;
}
