import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { AppService } from './app.service';
import { Observable } from 'rxjs';

export interface ValidateVoucherResponse {
    valid: boolean;
    messages: string[];
}

export interface VoucherDetails {
    code: string;
    type: VoucherType;
    partner: string;
}

export enum VoucherType {
    discount = 'DISCOUNT',
    merchandising = 'MERCHANDISING_ACTION',
    kam = 'KAM',
    quoteAssist = 'QUOTE_ASSIST',
}

@Injectable({
    providedIn: 'root',
})
export class VoucherService {
    constructor(private appService: AppService, private http: HttpService) {}

    public validateVoucher(
        voucherCode: string,
        customerNumber: string,
        materialNumbers: string[],
        useCase: 'default' | 'QuoteAssist'
    ): Observable<ValidateVoucherResponse> {
        const url = `${environment.http.baseUrl}voucher`;
        const salesOrg = this.appService.getSalesOrg();
        const language = this.appService.getLanguageOnly();
        const body = {
            salesOrg,
            language,
            materialNumbers,
            voucherCode: voucherCode.toUpperCase(),
            customerNumber,
            useCase,
        };
        return this.http.post<ValidateVoucherResponse>(url, body);
    }

    public getVoucherDetails(voucherCode: string): Observable<VoucherDetails> {
        return this.http.get<VoucherDetails>(
            `${environment.http.baseUrl}voucher/${encodeURIComponent(voucherCode.trim().toUpperCase())}`
        );
    }
}
