import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: 'breadcrumbs.component.html',
    styleUrls: ['breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    @Input()
    public formerPage: string;
    @Input()
    public currentPage: string;
    @Input()
    public navigateTo: string | string[];
    @Input()
    public queryParams: { [k: string]: any };
}
