import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    constructor() {}

    public enabled(featureName: string): boolean {
        return environment.feature[featureName];
    }
    public disabled(featureName: string): boolean {
        return !environment.feature[featureName];
    }
}
