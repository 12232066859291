<div
    *ngIf="dataSnackBar"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="20px"
    class="snack-bar"
    [class.failure]="dataSnackBar?.isFailure"
>
    <div fxFlex="0 1 auto" fxLayoutAlign="start center" class="message">
        {{ dataSnackBar?.message }}
    </div>
</div>
