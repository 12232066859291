import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-search-and-filter',
    templateUrl: './search-and-filter.component.html',
    styleUrls: ['./search-and-filter.component.scss'],
})
export class SearchAndFilterComponent {
    public maxDate = moment().utc();
    public searchValue: string;

    range = new FormGroup<{ start: FormControl<Date>; end: FormControl<Date> }>({
        start: new FormControl<Date>(null),
        end: new FormControl<Date>(null),
    });

    @Output()
    public offerSearch = new EventEmitter<any>();

    constructor() {}

    resetDate() {
        this.range.controls.start.setValue(null);
        this.range.controls.end.setValue(null);
        this.triggerSearch();
    }

    resetSearch() {
        this.searchValue = '';
        this.triggerSearch();
    }

    triggerDate() {
        if (this.range.controls.start.value && this.range.controls.end.value) {
            this.triggerSearch();
        } else {
            return;
        }
    }

    public triggerSearch() {
        const toDate = this.range.controls.end.value || new Date();
        const toDatePlus1 = (toDate ? moment(toDate) : moment().utc()).add(1, 'days').startOf('day').toDate();

        const arg = {
            fromDate: new Date(this.range.controls.start.value),
            toDate: toDatePlus1,
            search: this.searchValue,
        };
        this.offerSearch.emit(arg);
    }
}
