import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppInsightsService {
    private appInsights: ApplicationInsights;
    constructor() {}

    public init() {
        if (environment.appInsights.enabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: environment.appInsights.connectionString,
                    enableAutoRouteTracking: true,
                },
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
        }
    }
    public setUser(userId: string, accountId: string) {
        if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(userId, accountId, true);
        }
    }
}
