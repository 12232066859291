import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../_shared/guards/auth.guard';
import { ConfigurationComponent } from './configuration.component';
import { maintenanceGuard } from '../_shared/guards/maintenance.guard';

const configurationRoutes: Routes = [
    {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [authGuard, maintenanceGuard],
        data: {
            canChangeLanguage: false,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(configurationRoutes)],
    exports: [RouterModule],
})
export class ConfigurationRouting {}
