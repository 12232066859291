<app-card fxFlex [isFooter]="true" [isHeader]="true" class="product-list-card">
    <span class="product-list-title" header>{{ 'CONFIGURATION.PANEL.TITLE.LIST_OF_PRODUCTS' | translate }}</span>

    <div fxFlex body class="scroll-wrapper">
        <div *ngFor="let group of productListViewModel">
            <ng-container *ngIf="group.products?.length">
                <app-product-group
                    [products]="group.products"
                    [translationKey]="group.translationKey"
                ></app-product-group>
            </ng-container>
        </div>
    </div>
    <div footer>
        <mat-progress-bar
            mode="indeterminate"
            *ngIf="productListService.isProductListLoading$ | async"
        ></mat-progress-bar>
    </div>
</app-card>
