import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { ForbiddenComponent } from './forbidden.component';

@NgModule({
    declarations: [ForbiddenComponent],
    imports: [CommonModule, SharedModule],
    schemas: [NO_ERRORS_SCHEMA],
})
export class ForbiddenModule {}
