<div class="search-and-filter-container" fxLayout="row" fxLayoutAlign="end center">
    <mat-form-field class="bordered-field datepicker-field-container">
        <mat-label>{{ 'OVERVIEW.DATE_PICKER.PLACEHOLDER' | translate }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
            <input matStartDate formControlName="start" [max]="range.controls.end.value" />
            <input matEndDate formControlName="end" [max]="maxDate" (ngModelChange)="triggerDate()" />
        </mat-date-range-input>
        <button
            *ngIf="range.controls.start.value && range.controls.end.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="resetDate()"
        >
            <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="range.controls.start.hasError('matDatepickerMax')">{{
            'OVERVIEW.DATE_PICKER.MAX_DATE_INVALID' | translate
        }}</mat-error>
        <mat-error
            *ngIf="
                !range.controls.start.hasError('matDatepickerMax') &&
                (range.controls.start.invalid || range.controls.end.invalid)
            "
            >{{ 'OVERVIEW.DATE_PICKER.INVALID_DATE' | translate }}</mat-error
        >
    </mat-form-field>
    <div class="search-container">
        <mat-form-field class="bordered-field search-field-container">
            <input
                matInput
                placeholder="{{ 'OVERVIEW.SEARCH_PLACEHOLDER' | translate }}"
                [(ngModel)]="searchValue"
                (keyup.enter)="triggerSearch()"
            />
            <button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
                <mat-icon>close</mat-icon>
            </button>
            <button mat-icon-button type="button" matSuffix (click)="triggerSearch()">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
