import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { OverviewComponent } from './overview.component';
import { OverviewRouting } from './overview.routing';

@NgModule({
    declarations: [OverviewComponent],
    imports: [CommonModule, OverviewRouting, SharedModule],
})
export class OverviewModule {}
