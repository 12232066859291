import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { AppInsightsService } from './app-insights.service';
import { InstanaService } from './instana.service';

// Declare interface for Google Analytics 4
declare global {
    interface Window {
        dataLayer: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    public isUserLoggedIn$ = new BehaviorSubject<boolean | undefined>(undefined);
    public isUserAuthorized$ = new BehaviorSubject<boolean | undefined>(undefined);
    public userInfo$ = new BehaviorSubject<UserInfo | undefined>(undefined);
    public languagesReady$ = new BehaviorSubject<boolean | undefined>(undefined);

    public get isUserAnEmployee() {
        return this.userInfo$.value !== undefined && this.userInfo$.value.role === 'employee';
    }

    constructor(
        private router: Router,
        private http: HttpService,
        private appInsights: AppInsightsService,
        private instana: InstanaService
    ) {
        const userWithRoles$ = this.userInfo$.pipe(filter((user) => user !== undefined && user.iamRoles !== undefined));
        const userAuthorizedCheck$ = userWithRoles$.pipe(
            map((user) => this.isAuthorizedToUseQuoteAssist(user)),
            switchMap((state) =>
                state === false ? of(state) : this.languagesReady$.pipe(filter((v) => v !== undefined))
            )
        );
        const liteAccountCheck$ = userWithRoles$.pipe(map((user) => !!user.isLite));

        combineLatest([userAuthorizedCheck$, liteAccountCheck$]).subscribe(
            ([userAuthorizedCheck, liteAccountCheck]) => {
                this.isUserAuthorized$.next(userAuthorizedCheck);

                if (liteAccountCheck) {
                    this.router.navigate(['forbidden'], {
                        state: {
                            isLite: true,
                        },
                    });
                } else {
                    if (userAuthorizedCheck !== undefined && !userAuthorizedCheck) {
                        this.router.navigate(['forbidden']);
                    }
                }
            }
        );
    }

    public mapIdentityTypeToRole(identityType: string) {
        switch (identityType) {
            case 'ViInt':
            case 'ViAcc':
            case 'ViExt':
                return 'employee';
            case 'KuMA':
                return 'customer';
            default:
                return 'unknown';
        }
    }

    private isAuthorizedToUseQuoteAssist(user: UserInfo): boolean {
        return user.iamRoles !== undefined && user.role && user.iamRoles.includes('Quoteassist.Default');
    }

    public getLoggedInUserInfo(): void {
        this.http.get(environment.http.users).subscribe((res) => {
            let role;
            let identityProp;

            if (res.properties) {
                identityProp = res.properties.find((p) => p.name === 'IdentityType');
                if (identityProp) {
                    role = this.mapIdentityTypeToRole(identityProp.value);
                }
            }

            const user = <UserInfo>{
                userId: res.loginId,
                displayName: res.name && `${res.name.firstName} ${res.name.familyName}`,
                mail: res.contacts && res.contacts.email,
                viCompanyId: res.company && res.company.id,
                identityType: identityProp.value,
                role,
                iamRoles: res.applicationRoles,
                isLite: res.isLite,
            };

            this.appInsights.setUser(res.id, user.viCompanyId);
            if (!user.identityType.toLowerCase().startsWith('vi')) {
                this.instana.reportUser(res.id, user.userId, user.mail);
                this.instana.reportMetadataObject({
                    displayName: user.displayName,
                    role: user.role,
                    company: user.viCompanyId,
                    identityType: user.identityType,
                    iamRoles: user.iamRoles.join(', '),
                    isLite: user.isLite,
                });
            }
            this.userInfo$.next(user);
            // Push user data for Google Analytics 4 dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'user_profile_loaded',
                identityType: user.identityType,
                userId: res.id,
                userProfileLoaded: true,
            });
        });
    }
}

interface UserInfo {
    identityType: string;
    iamRoles?: string[];
    role: 'customer' | 'employee' | 'unknown';
    viCompanyId: string;
    mail: string;
    displayName?: string;
    userId?: string;
    isLite?: boolean;
}
