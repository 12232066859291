<div class="breadcrumbs" data-ut-breadcrumbs>
    <span
        data-at-back-breadcrumb
        fxHide.lt-md
        [routerLink]="navigateTo"
        [queryParams]="queryParams"
        class="bread-crumb-formerPage"
        >{{ formerPage }}</span
    >
    <span *ngIf="formerPage && currentPage" data-ut-separator fxHide.lt-md class="breadcrumb-separator"> \ </span>
    <span class="bread-crumb"> {{ currentPage }}</span>
</div>
